import axios from "axios";
import { axiosClient } from "../../../../setup/axios/SetupAxios";
import store from "../../../../setup/redux/Store";
import {
  GET_SERVICES,
  REVIEW_API_URL,
  SEND_MAIL_SERVICE_REQUEST,
  SUBSCRIBERS_API_URL,
  USER_TEMPLATE,
} from "../../../helper/APIURL";
import { ReviewFormModel } from "../../../pages/templates/components/Templates/common/reviewHelper/ReviewFormModel";
import { SubscribersFormModel } from "../../../pages/templates/components/Templates/common/subscribeHelper/SubcribersModal";
import { TemplateUserDataModel } from "../models/TemplateUserDataModel";
import { API_URL } from "../../../helper/EnvVar";
import { ServiceRequestModel } from "../models/ServiceRequestModel";

//For Global Template User Data Related Start ======>
// Get Services List
export function getTemplateServicesList(serviceName: string) {
  return axiosClient.get(`${GET_SERVICES}?limit=250&fields=*,svg.*&search=${serviceName}`);
}

//To send add new service request to admin
export function sendAddNewServiceRequestToAdmin(data: ServiceRequestModel) {
  return axiosClient.post(`${SEND_MAIL_SERVICE_REQUEST}`, { ...data });
}

export async function getVCFFile(templateUserData: TemplateUserDataModel) {
  return await axiosClient.post(`/vcf/download`, templateUserData);
}

//<======For Global Template User Data Related End
export function addTemplateUserData(data: TemplateUserDataModel) {
console.log(data,'data');  
return axiosClient.patch(`${USER_TEMPLATE}/${data.id}`, {
    ...data,
    status: "Published",
    }, 
  );
}

export function addTemplateUserDataForMobileUser(data: TemplateUserDataModel, token: string) {
  return axios.patch(`${API_URL}${USER_TEMPLATE}/${data.id}`, {
    ...data,
    status: "Published",
    }, 
    {
      headers : {
        Authorization : `Bearer ${token}`
      }
    }
  );
}

export async function addFiles(file: File){
  const formData = new FormData();   
  formData.append(`${file.name}`,file)
  return await axiosClient.post("/files", formData, { headers: { 'Content-Type': 'multipart/form-data' }});
}

export function getTemplateUserDataByMobileNumber(mobileNumber: string) {
  return axiosClient.get(
    `${USER_TEMPLATE}?filter[mobileNumber][_eq]=${mobileNumber}&fields=*,profile_image.*,company_logo.*,services.*,services.svg.*,service_id.services.*,service1.*.*,service2.*.*,service3.*.*,service4.*.*`
  );
}

export async function getTemplateUserDataByUserID() {
  const mystate = store.getState();
  return await axiosClient.get(
    `${USER_TEMPLATE}?filter[user_id][_eq]=${mystate.auth.user?.user_id}&fields=*,profile_image.*,company_logo.*,services.*,services.svg.*,industry.*,service_id.services.*,service1.*.*,service2.*.*,service3.*.*,service4.*.*`
  );
}

export async function createTemplateUserDataByUserID() {
  const mystate = store.getState();
  return await axiosClient.post(`${USER_TEMPLATE}`, { 
    ...mystate.templateUserData.templateUserData,
    user_id: mystate.auth.user?.id,
    mobileNumber: mystate.auth.user?.mobile_number || mystate?.auth?.mobileNumber,
    isWhatsapp: true,
    whatsAppLink: mystate.auth.user?.mobile_number || mystate?.auth?.mobileNumber,
  });
}

export async function getTemplateUserDataByID() {
  const mystate = store.getState();
  return await axiosClient.get(
    `${USER_TEMPLATE}?filter[id][_eq]=${mystate.templateUserData.templateUserData?.id}&fields=*,profile_image.*,company_logo.*,services.*,services.svg.*,service_id.services.*,service1.*.*,service2.*.*,service3.*.*,service4.*.*`
  );
}

//<====== Registered User Template Data Functions End
// Reviews Related Functions Start ======>

export async function addReviews(data: ReviewFormModel) {
    return await axiosClient.post(`${REVIEW_API_URL}`, { ...data });
  }
  
export async function getReviewsByTemplateID(user_template_id: string) {
  return await axiosClient.get(
    `${REVIEW_API_URL}?filter[user_template_id][_eq]=${user_template_id}&limit=10`
    );
}
  
  //<====== Reviews Related Functions End
      
  export async function addSubscribers(data: SubscribersFormModel) {
    return await axios.post(`${SUBSCRIBERS_API_URL}`, { ...data });
  }
  
  type UploadImageData = {
    profile : {
      title: string;
      image: string;
    },
    company : {
      title: string;
      image: string;
    }
  }

  export async function uploadImages(data: UploadImageData) {
    const formData = new FormData();
    if (data.profile.image) {
      formData.append("title", data.profile.title);
      formData.append("folder", "661a25e7-1be1-41da-865f-c9466c87129d");
      formData.append("file", data.profile.image);
    }
    if (data.company.image) {
      formData.append("title", data.company.title);
      formData.append("folder", "661a25e7-1be1-41da-865f-c9466c87129d");
      formData.append("file", data.company.image);
  }

  return await axiosClient({
    method: "post",
    url: "/files",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
