import React, { Suspense, lazy, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "../_start/layout/core";
import { FallbackView } from "../_start/partials";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthDataRedux } from "./modules/auth/redux/ReduxDispatchExport";
import { checkToken } from "./helper/helper";
import { PocketWebsite } from "../PocketWebsite";
import { RootState } from "../setup";
import NotFoundComponent from "./pages/NotFound/NotFoundComponent";
import Logout from "./modules/auth/Logout";
import Contactus from "./pages/Contact/Contactus";
import Razorpay from "./pages/Payment/Razorpay";
import Aboutus from "./pages/About/Aboutus";
import AboutusNew from "./pages/About/AboutusNew";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsCondition from "../pages/TermsCondition";
import AuthPage from "./modules/auth";
import EditTemplate from "./pages/templates/EditTemplate";
import ProfilePage from "./modules/profile/ProfilePage";
import Dashboard from "../pages/Dashboard";

const Home = lazy(() => import(/* webpackChunkName: "home" */ "./pages/Home/Home"));

type Props = {
  basename:string 
}

const App: React.FC<Props>= () => {
  const dispatch = useDispatch();
  const [siteView, setSiteView] = useState<boolean>(false);
  const auth = useSelector((state: RootState) => state.auth);

  const url = window.location.href;
  const mobileNumberCheck = url.split("/");
  const mobileNumber = mobileNumberCheck[url.split("/").length - 1];

  useEffect(() => {
    if(mobileNumber.length >= 10) {
        if(/^[0-9]*$/.test(`${mobileNumber}`)){
          setSiteView(true);
        }
      }else{
        if (/^\d{10}$/.test(`${mobileNumber}`)) {
          setSiteView(true);
        }
        else{
          setSiteView(false)
        }
      }
  }, [mobileNumber]);

  window?.addEventListener('load', function() {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap";
    link.crossOrigin = "anonymous";
    document.head.appendChild(link);
  })

  useEffect(() => {
    if (checkToken(auth)) {
      dispatch(AuthDataRedux.actions.logout());
    }
  }, [auth.isAuthorized]);

  return (
    <>
        {siteView ? (
          <>
            <PocketWebsite mobileNumber={mobileNumber} />
          </>
        ) : (
          <ThemeProvider>
            <Suspense fallback={<FallbackView />}>
              <Routes>
                 <Route
                  path="*"
                  element={auth?.isAuthorized ? <></> : <></>}
                ></Route>
                <Route
                  path="*"
                  element={!auth?.isAuthorized ? <></> : <NotFoundComponent />}
                ></Route> 
                <Route
                  path="/home"
                  element={
                    !auth?.isAuthorized ? (
                      <Home />
                    ) : (
                      <Navigate replace to="/profile/preview" />
                    )
                  }
                ></Route>
                <Route
                  path="/home/:section"
                  element={
                    !auth?.isAuthorized ? (
                      <Home />
                    ) : (
                     <Navigate replace to="/profile/preview" />
                    )
                  }
                ></Route>
                <Route
                  path="/"
                  element={
                    !auth?.isAuthorized ? (
                      <Home />
                    ) : (
                     <Navigate replace to="/profile/preview" />
                    )
                  }
                ></Route>
                <Route path="/logout" element={<Logout />} />
                <Route path="/contactus" element={<Contactus  />}></Route>
                <Route path="/razorpay" element={<Razorpay />}></Route>
                <Route path="/aboutus" element={<Aboutus />}></Route>
                <Route path="/aboutus-new" element={<AboutusNew />}></Route>
                <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                <Route
                  path="/terms-condition"
                  element={<TermsCondition />}
                ></Route>
                <Route path="auth/*" element={<AuthPage />} />
                <Route path="/login" element={<AuthPage />} />
                <Route path="/edit-template/:id" element={<EditTemplate />} />
                <Route path="/profile/*" element={<ProfilePage />} />
                <Route path="/dashboard" element={<Dashboard />} />
              </Routes>
            </Suspense>
          </ThemeProvider>
        )}
        <ToastContainer
          containerId="toast-id"
          position="top-center"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </>
  );
};

export { App };
