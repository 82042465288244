import React from "react";
import Switch from "./Switch";
import "../../scss/template/social-media.scss";
import SVG from "react-inlinesvg";
import InputField from "./InputField";
import { FormikErrors } from "formik";

type Props = {
  type?: string;
  svg: string;
  placeHolder: string | React.ReactNode;
  id: string;
  name?: string;
  switchId?: string;
  switchHtmlFor?: string;
  switchName?: string;
  value: string | undefined;
  checked?: boolean;
  defaultChecked?: boolean;
  isSwitch?: boolean;
  isTouched?: boolean;
  errorMsg?: string | string[] | FormikErrors<string> | FormikErrors<string>[];
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onLinkInput?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
};

const SocialMedia = (props: Props) => {
  return (
    <>
      <div className="media-filed">
        <div className="social-icon">
          <SVG src={props.svg} />
        </div>
        <div className="center-feild floating-group ">
          <InputField
            type={props.type ? props.type : 'text'}
            className="mb-3"
            name={props.name}
            placeholder={" "}
            id={props.id}
            value={props.value ? props.value : ""}
            onChange={props.onLinkInput}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            autoFocus={false}
          />
          <label htmlFor="whatsapp" className="label float-label">
            {props.placeHolder}
          </label>
        </div>
        <Switch
          id={props.switchId}
          checked={props.checked}
          defaultChecked={props.defaultChecked}
          name={props.switchName}
          onChange={props.onChange}
          htmlFor={props.switchHtmlFor}
        />
      </div>
      {props.isTouched && props.errorMsg !== "" && (
        <div className="fv-plugins-message-container danger-validate">
          <div className="fv-help-block">{props.errorMsg}</div>
        </div>
      )}
    </>
  );
};
export default SocialMedia;
