import React from "react";
import SVG from "react-inlinesvg";
import editBtn from "../../templates/images/edit-pen.svg";
import loading from "../../templates/images/misc/loading.gif";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import { RootState } from "../../setup";
import { ASSEST_URL } from "../../app/helper/EnvVar";
import { FormikErrors } from "formik";

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onLoad?: React.ReactEventHandler<HTMLInputElement> | undefined;
  name: string;
  isTouched: boolean;
  changeEvent?: boolean;
  setChangeEvent: (value: boolean) => void;
  errorMsg: string | string[] | FormikErrors<string> | FormikErrors<string>[];
  value?: string;
  show?: boolean;
  setShowCropper: (value: boolean) => void;
  isLoading?: boolean;
  setIsLoading: (value: boolean) => void;
  showCropperFor?: string;
  setShowCropperFor: (value: string) => void;
  title: string;
  className: string;
  id: string;
  userProfile?: boolean | undefined;
  companyLogoFile?: string;
  file?: string | undefined
};

export const UploadLogoFile: React.FC<Props> = ({
  onChange,
  onLoad,
  value,
  name,
  isTouched,
  errorMsg,
  className,
  id,
  userProfile,
  setShowCropper,
  isLoading,
  showCropperFor,
  setShowCropperFor,
  changeEvent,
  setChangeEvent,
  companyLogoFile,
  file
}) => {
  const templateUserData = useSelector(
    (state: RootState) => state && state.templateUserData && state.templateUserData.templateUserData && state.templateUserData.templateUserData
  );
  
  const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setChangeEvent(true)
    if(templateUserData?.profile_image?.filename_disk && showCropperFor === "profileImage" && changeEvent === false){
      event.preventDefault();
      setShowCropper(true);
      setShowCropperFor("profileImage")
      event.currentTarget.value = '';
    }else if((templateUserData?.company_logo?.filename_disk || file) && showCropperFor === "companyLogo" && changeEvent === false){
      event.preventDefault();
      setShowCropper(true);
      setShowCropperFor("companyLogo")
      event.currentTarget.value = '';
    }else{
      onChange(event as unknown as React.ChangeEvent<HTMLInputElement>)
      setChangeEvent(false)
    }
  }

  return (
    <>
      <input
        type={"file"}
        className={className}
        id={id}
        name={name}
        onChange={(e) => {
          onChange(e)
        }}
        onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => onInputClick(e)}
        accept="image/*"
        value={value}
      />
      {!userProfile ? (
        (name === "profile_image") ? <div className="d-flex flex-column align-items-center">
          <div className="upload-images">
            <Avatar src={`${templateUserData?.profile_image?.filename_disk ? `${ASSEST_URL}/${templateUserData?.profile_image?.filename_disk}` : ""}`} style={{ width: "130px", height: "130px" }} />
          </div>
          <div className="edit-pen">
            <SVG src={editBtn} style={{ cursor: "pointer" }} />
          </div>
        </div> : <div className="d-flex flex-column upload-logo-wrap">
          <div className="upload-images"> 
          {isLoading ? <Avatar src={loading} /> : <Avatar  
                onLoad={onLoad}
                src={`${
                  templateUserData?.company_logo?.filename_disk
                    ? `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}`
                    : companyLogoFile
                  }`} 
                /> }
          </div>
          <div className="edit-pen">
            <SVG src={editBtn} style={{ cursor: "pointer" }} />
          </div>
        </div>
      ) : (
        <SVG src={editBtn} style={{ cursor: "pointer" }} />
      )}
      {isTouched && errorMsg !== "" && (
        <div className="fv-plugins-message-container danger-validate">
          <div className="fv-help-block">{errorMsg}</div>
        </div>
      )}
    </>
  );
};
