import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "../../app/helper/EnvVar";
import { AuthDataRedux } from "../../app/modules/auth/redux/ReduxDispatchExport";
import store from "../redux/Store";

export const axiosClient = axios.create({baseURL: API_URL});
// export default function setupAxios(axiosClient: any, store: any) {
  axiosClient.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const { auth: { accessToken } } = store.getState();
      if (accessToken) {
        if(config.headers){
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }  
      return config;
    },
    (err) => {  
      return Promise.reject(err)
    }
  );
    
  axiosClient.interceptors.response.use((res) => {
    return res;
    }, async (err) => {
      const originalRequest = err.config;
      originalRequest.headers = JSON.parse(JSON.stringify(originalRequest.headers || {}));
      if(err.response.data.errors[0].extensions.code === "INVALID_TOKEN" || err.response.data.errors[0].extensions.code === "INVALID_CREDENTIALS"){
        store.dispatch(AuthDataRedux.actions.logout());
      }else if((err.response.status === 401 || err.response.status === 403) && !originalRequest._retry) {
        originalRequest._retry = true;
        store.dispatch(AuthDataRedux.actions.refreshToken())
        setTimeout(()=>{
          return axiosClient(originalRequest)
        }, 3000);
      }else{
        store.dispatch(AuthDataRedux.actions.logout());
      }
      return Promise.reject(err.response);
    }
  );
// }
