import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import clsx from "clsx";
import { AuthDataRedux } from "../redux/ReduxDispatchExport";
import { RootState } from "../../../../setup";
import SVG from "react-inlinesvg";
import cardAuth from "../../../../templates/images/card-auth.jpeg";
import logoCenter from "../../../../templates/images/logo-center.svg";
import Countdown , { zeroPad } from 'react-countdown';
import { verifyMobileNumber } from "../redux/AuthCRUD";
import OTPInput from "./OTP/OTPForm";
import { FormattedMessage } from "react-intl"; 
import { Button } from "react-bootstrap-v5";

type otpForm = {
  otp_1: string;
  otp_2: string;
  otp_3: string;
  otp_4: string;
};
type CountProps = {
  minutes: number;
  seconds: number;
  completed: boolean;
}
const initialValues: otpForm = {
  otp_1: "",
  otp_2: "",
  otp_3: "",
  otp_4: "",
};
const initialCount: CountProps = {
  minutes: 0,
  seconds: 60000,
  completed: false
}

function OtpVerify() {
  const [loading, setLoading] = useState(false);
  const [validOTP, setValidOTP] = useState<boolean>(false);
  const [countValue, setCountValue] = useState(Date.now() + 60000);
  const [otpValue, setOTPValue] = useState("");
  const [checkOTP, setCheckOTP] = useState(false);
 
  const dispatch = useDispatch();
  const countRef = useRef<HTMLElement | null>(null);
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(()=> {
    messageOTP()
  }, [auth])

  useEffect(() => {
    if (!auth.mobileNumber) {
      setLoading(false);
      navigate(`/auth/login`);
    }
    if (auth.isAuthorized) {
      if(auth?.user?.firstName){
        setLoading(false);
        setTimeout(() => {
          navigate(`/profile/preview`);
        }, 600);
      }else{
        setLoading(false);
        setTimeout(() => {
          navigate(`/edit-template/${auth?.user?.selectedTemplateID}`);
        }, 600);
      }
    }   
  }, [auth.isAuthorized, auth?.mobileNumber]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      if(!auth.mobileNumber){
        navigate(`/auth/login`);
      }
      setLoading(true);
      setTimeout(() => {
        auth.mobileNumber && dispatch(AuthDataRedux.actions.verifyotp(otpValue, auth.mobileNumber));
        }, 200);
    },
  });

  useEffect(()=>{
    renderer(initialCount)
  },[])

  const resetValues = () => {
    setOTPValue(" ");
    document.getElementById('otp_1')?.focus();
    setLoading(false);
    setValidOTP(false);
  }
 
  const messageOTP = () => {
    if(auth?.message === "OTP verified"){
      setCheckOTP(true)
      setValidOTP(true)
    } else if(auth?.message === "OTP verfication failed, Please re-enter your OTP"){
      setValidOTP(false)
      setCheckOTP(false)
      resetValues();
    } else if(auth?.message === "OTP verification failed") {
      setValidOTP(false)
      setCheckOTP(false)
      resetValues();
    }else{
     console.log('none')
    }
  }


  const handleOTPInsert =  (value: string) => {
    setOTPValue(value)
    if(value?.length === 4){
      setValidOTP(true)
    }
  }

  const resendOTP = () => {
    setOTPValue(" ")
    document.getElementById('otp_1')?.focus();
    setLoading(false);
    auth && auth.mobileNumber && verifyMobileNumber(auth.mobileNumber)
    setCountValue(Date.now() + 60000)
    renderer(initialCount)
  }

  const renderer = ({ minutes, seconds, completed } : CountProps) => {
    if (completed) {
      // Render a completed state
      return <span style={{ cursor:"pointer" , color: "#0e9ec9" }} onClick={() => resendOTP()}>
          <FormattedMessage
            id="otp.resend.text"
            defaultMessage={"Resend OTP"}
          />
        </span>;
    } else {
      // Render a countdown
      countRef?.current?.start();
      return <span> {zeroPad(minutes)} : {zeroPad(seconds)} </span>;
      }
  };


  return (
    <>
      <main className="main">
        <div className="authentication-page">
          <div className="container">
            <div className="auth-inner">
              <div className="row">
                <div className="col-lg-5 order-2 order-lg-1">
                  <div className="side-left  text-center">
                    <div className="top-col  ">
                      <h2 className="title">
                      <FormattedMessage
                        id="login.few.click.1"
                        defaultMessage="A few click away"
                      /> <br />
                      <FormattedMessage
                        id="login.few.click.2"
                        defaultMessage="from creating your"
                      /> <br />
                      <FormattedMessage
                        id="login.few.click.3"
                        defaultMessage="stunning digital card."
                      />
                      </h2>
                    </div>
                    <img
                      src={cardAuth}
                      alt="card-auth"
                      width={360}
                      height={360}
                    />
                  </div>
                </div>
                <div className="col order-1 order-lg-2 d-flex flex-column justify-content-center align-items-center">
                  <form
                    className="auth-form w-100"
                    onSubmit={formik.handleSubmit}
                    noValidate
                    id="otp_form"
                  >
                    <a
                      href="/"
                      className="logo-center mb-5 d-flex justify-content-center"
                    >
                      <SVG src={logoCenter} className="logo-main" />
                    </a>
                    <h3 className="center-title">
                      <FormattedMessage
                        id="otp.4digit"
                        defaultMessage="Enter 4 Digit OTP"
                      />
                    </h3>
                    <div className="otp-wrap">
                      <div className="mb-3 mt-4 d-flex otp-field-wrap justify-content-center">
                        <OTPInput
                          styleclasses={clsx(
                            `form-control ${loading ? 'disabled' : ''}`,
                            {
                              "is-invalid danger-validate":
                                checkOTP && !validOTP,
                            },
                            {
                              "is-valid": checkOTP && validOTP,
                            }
                          )}
                          name="otp_1"
                          id="otp_1"
                          otpvalue={otpValue}
                          onChange={handleOTPInsert}
                          otplength={4}
                          isDisabled={loading ? true : false}
                        />
                      </div>
                      <div className="otp-text mb-3">
                        <FormattedMessage
                          id="otp.get.otp"
                          defaultMessage="Didn't get OTP?"
                        />{" "}
                        <Countdown
                          date={countValue}
                          renderer={renderer}
                          autoStart={true}
                          ref={countRef}
                        />
                      </div>
                      <Button
                        type="submit"
                        name="button"
                        className="w-auto min-w-160"
                        id="kt_login_signin_form_submit_button"
                        disabled={
                          otpValue?.length < 4
                            ? true
                            : loading ? true : false
                        }
                      > 
                        {!loading ? <FormattedMessage id="otp.verify" defaultMessage={"Verify OTP"} /> : <FormattedMessage id="otp.please.wait" defaultMessage={"Please Wait..."} />}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default OtpVerify;
