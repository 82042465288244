import React, { useEffect, useState } from "react";
import { getUserByQueryStringToken } from "../app/modules/auth/redux/AuthCRUD";
import { getTemplateUserDataByMobileNumber } from "../app/modules/template/redux/TemplateUserDataCRUD";
import { TemplateUserDataModel } from "../app/modules/template/models/TemplateUserDataModel";
import MobilePreview, { MobileUserData } from "./MobilePreview";
import * as templateUserDataRedux from "../app/modules/template/redux/TemplateUserDataRedux";
import { useDispatch } from "react-redux";

type MobileProps = {
  token: string
}

type TokenUser = {
  data : MobileUserData
}

type UserData = {
  data: TemplateUserDataModel
}

function MobileProfilePreview({ token }: MobileProps ) {
  const dispatch = useDispatch()
  const [tokenUser, setTokenUser] = useState<TokenUser>();
  const [userData, setUserData] = useState<UserData>();
  const [error, setError] = useState<string>();

  useEffect(() =>{
   if(token){
    getUserByQueryStringToken(token).then(res => {
      setTokenUser(res.data)
      dispatch(templateUserDataRedux.actions.setUserTemplateByID(true))
    }).catch(err => {
      setError(err)
      console.error(err,'err')
    });
   } 
  },[token])

  useEffect(()=>{
    if(tokenUser){
      getTemplateUserDataByMobileNumber(tokenUser?.data?.first_name).then(res => {
        setUserData(res?.data)
        dispatch(templateUserDataRedux.actions.setUserTemplateByID(true))
      }).catch(err => {
        console.error(err)
      })
    }
  },[tokenUser])

  return (
      <>
       {error && <div style={{
          height: "100vh",
          display: 'flex',
          alignItems: "center" ,
          justifyContent: "center",
          textAlign: 'center',
        }}>
        <ul style={{ listStyle: 'none' }}>
          <li>
            {error.toString()}
          </li>
          <li>
            Please Logged in again 
          </li>
        </ul>
       </div>}
       {userData && <MobilePreview mobileUserData={userData} token={token && token} />}
      </>
  );
}

export default MobileProfilePreview;
