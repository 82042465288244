import axios from "axios";
import { axiosClient } from "../../../../setup/axios/SetupAxios";
import { TEMPLATES } from "../../../helper/APIURL";
import { TemplateListModel } from "../models/TemplateListModel";
import { API_URL } from "../../../helper/EnvVar";

export const getTemplateList = () => {
  return axiosClient.get<TemplateListModel>(`${TEMPLATES}?fields=*,image.*`);
};

export const getTemplateListForMobileUser = () => {
  return axios.get<TemplateListModel>(`${API_URL}${TEMPLATES}?fields=*,image.*`);
};

export function getTemplateByID(id: string) {
  return axiosClient.get<TemplateListModel>(`${TEMPLATES}/${id}?fields=*,image.*`);
}
