import React from "react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import NotFoundComponent from "../../pages/NotFound/NotFoundComponent";
import Login from "./components/Login";
import OtpVerify from "./components/OtpVerify";

function AuthPage() {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <>
      <div className="login-content flex-lg-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden py-20 mx-auto mw-500px w-100">
        <Routes>
          <Route path="*" element={<NotFoundComponent />}></Route>    
          <Route path="/login" element={<Login />} />
          <Route path="/otpverify" element={<OtpVerify />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </div>
    </>
  );
}

export default AuthPage
