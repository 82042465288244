import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { Action } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { 
  // createTemplateUserDataByUserID,
  getTemplateServicesList,
  getTemplateUserDataByUserID,
} from "./TemplateUserDataCRUD";
import { TemplateUserDataModel } from "../models/TemplateUserDataModel";
import { ServicesListModel } from "../models/ServicesModel";
export interface ActionWithPayload<T> extends Action {
  payload?: T;
}
export interface GetServicesListAction {
  type: typeof actionTypes.GetServicesList;
  payload: {
    serviceName: string;
  };
}

export const actionTypes = {
  SetTemplateUserData: "[Set Template User Data] Action",
  UnsetTemplateUserData: "[Unset Template User Data] Action",
  SetFromEdit: "[Set From Edit] Action",
  UnsetFromEdit: "[Unset From Edit] Action",
  SetServicesList: "[Set Services List] Action",
  GetServicesList: "[Get Services List] Action",
  UnSetServicesList: "[UnSet Services List] Action",
  GetSetTemplateUserData: "[Get Set Template User Data] Action",
  SetUserTemplateByID: "[Set User Template By User Id] Action",
  setTemplateLoading: "[Set Template Loading] Action",
};

const initialTemplateState: InitTemplateUserDataState = {
  templateUserData: undefined,
  fromEdit: false,
  templateServicesList: undefined,
  ReviewFormState: "",
  userTemplateByID: false,
  templateLoading: false,
};

export interface InitTemplateUserDataState {
  templateUserData?: TemplateUserDataModel;
  fromEdit?: boolean;
  templateServicesList?: ServicesListModel[];
  ReviewFormState: string;
  userTemplateByID?: boolean;  
  templateLoading?: boolean;  
}

export const reducer = persistReducer(
  {
    storage,
    key: "templateUserData-01",
    whitelist: ["templateUserData", "templateServicesList", 'userTemplateByID','templateLoading'],
  },
  (
    state: InitTemplateUserDataState = initialTemplateState,
    action: ActionWithPayload<InitTemplateUserDataState>
  ) => {
    switch (action.type) {
      case actionTypes.SetTemplateUserData: {
        const templateUserData = action && action.payload && action.payload.templateUserData && action.payload?.templateUserData;
        return {
          ...state,
          templateUserData: { ...templateUserData, id: templateUserData?.id },
        };
      }
      case actionTypes.UnsetTemplateUserData: {
        return initialTemplateState;
      }
      case actionTypes.SetFromEdit: {
        const fromEdit = action.payload?.fromEdit;
        return { ...state, fromEdit: fromEdit };
      }
      case actionTypes.UnsetFromEdit: {
        return { ...state, fromEdit: false };
      }
      case actionTypes.SetServicesList: {
        const templateServicesList = action.payload?.templateServicesList;
        return { ...state, templateServicesList };
      }
      case actionTypes.UnSetServicesList: {
        return initialTemplateState;
      }
      case actionTypes.SetUserTemplateByID: {
        const userTemplateByID = action.payload?.userTemplateByID;
        return { ...state, userTemplateByID };
      }
      case actionTypes.setTemplateLoading: {
        const templateLoading = action.payload?.templateLoading;
        return { ...state, templateLoading };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  setTemplateUserData: (templateUserData: TemplateUserDataModel) => ({
    type: actionTypes.SetTemplateUserData,
    payload: { templateUserData },
  }),
  getSetTemplateUserData: () => ({ type: actionTypes.GetSetTemplateUserData }),
  unsetTemplateUserData: () => ({ type: actionTypes.UnsetTemplateUserData }),
  setFromEdit: (fromEdit: boolean) => ({
    type: actionTypes.SetFromEdit,
    payload: { fromEdit },
  }),
  unsetFromEdit: () => ({ type: actionTypes.UnsetFromEdit }),
  getServicesList: (serviceName: string) => ({
    type: actionTypes.GetServicesList,
    payload: { serviceName },
  }),
  searchServiceList: (serviceName: string) => ({
    type: actionTypes.GetServicesList,
    payload: { serviceName },
  }),
  setServicesList: (templateServicesList: ServicesListModel) => ({
    type: actionTypes.SetServicesList,
    payload: { templateServicesList },
  }),
  unSetServicesList: () => ({
    type: actionTypes.UnSetServicesList,
  }),
  setUserTemplateByID: (userTemplateByID: boolean) => ({
    type: actionTypes.SetUserTemplateByID,
    payload: { userTemplateByID },
  }),
  setTemplateLoading: (templateLoading: boolean) => ({
    type: actionTypes.setTemplateLoading,
    payload: { templateLoading },
  }),
};

export function* TemplateUserDataSaga() {
  yield takeLatest(
    actionTypes.GetServicesList,
    function* servicesListSaga(Action: GetServicesListAction) {
      const serviceName = Action.payload && Action.payload.serviceName;
      try {
        const {
          data: { data },
        } = yield getTemplateServicesList(serviceName);
        yield put(actions.setServicesList(data));
      } catch (e) {
        console.log("Unable to load Services List, error trace is: ", e);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetSetTemplateUserData,
    function* GetSetTemplateUserDataSaga() {
      try {
        const {
          data: { data },
        } = yield call(getTemplateUserDataByUserID);
        if(data[0]){
          yield put(actions.setTemplateUserData(data[0]))
          yield put(actions.setUserTemplateByID(true))
        }
      } catch (e) {
        console.log(
          "Unable to get user template data by user ID, error trace is: ",
          e
        );
      }
    }
  );
}
