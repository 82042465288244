import React from "react";
import { PUBLIC_URL } from "../../helper/EnvVar";
import { UploadImg } from "../../../_start/helpers/components/UploadImg";
import { PageTitle } from "../../../_start/layout/core";

const Aboutus: React.FC = () => {
    return (
        <>
            <div className="aboutus-page steps-section">
                <div className="content-wrap">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="img-div">
                                <UploadImg path={`${PUBLIC_URL}media/images/vision-new.png`} altText="vision" width="630" height="408" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="contents-block">
                                <h3 className="text-dark fs-1 fw-bolder lh-lg">Our vision</h3>
                                <p className="mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit dolores deserunt saepe amet voluptatem, commodi itaque eveniet maiores vero fugiat ipsum architecto cum sequi, dolor ex in, dicta nisi maxime?</p>
                                <p className="mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit dolores deserunt saepe amet voluptatem, commodi itaque eveniet maiores vero fugiat ipsum architecto cum sequi, dolor ex in, dicta nisi maxime?</p>
                                <p className="mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit dolores deserunt saepe amet voluptatem, commodi itaque eveniet maiores vero fugiat ipsum architecto cum sequi, dolor ex in, dicta nisi maxime?</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="img-div">
                                <UploadImg path={`${PUBLIC_URL}media/images/achive.png`} altText="achive" width="760" height="465" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="contents-block">
                                <h3 className="text-dark fs-1 fw-bolder lh-lg">Our Approach</h3>
                                <p className="mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit dolores deserunt saepe amet voluptatem, commodi itaque eveniet maiores vero fugiat ipsum architecto cum sequi, dolor ex in, dicta nisi maxime?</p>
                                <p className="mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit dolores deserunt saepe amet voluptatem, commodi itaque eveniet maiores vero fugiat ipsum architecto cum sequi, dolor ex in, dicta nisi maxime?</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="img-div">
                                <UploadImg path={`${PUBLIC_URL}media/images/approach.webp`} altText="vision-new" width="626" height="417" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="contents-block">
                                <h3 className="text-dark fs-1 fw-bolder lh-lg">Our Proccess</h3>
                                <p className="mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit dolores deserunt saepe amet voluptatem, commodi itaque eveniet maiores vero fugiat ipsum architecto cum sequi, dolor ex in, dicta nisi maxime?</p>
                                <p className="mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit dolores deserunt saepe amet voluptatem, commodi itaque eveniet maiores vero fugiat ipsum architecto cum sequi, dolor ex in, dicta nisi maxime?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageTitle>About us</PageTitle>
        </>
    );
};

export default Aboutus