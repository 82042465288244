import { useFormik } from "formik";
import { nanoid } from "nanoid";
import React, { ReactNode, lazy, useEffect, useState } from "react";
import { CirclePicker } from "react-color";
import { useNavigate } from "react-router";
import { COLOR, colors, moduleColor } from "../app/helper/constant";
import { ASSEST_URL } from "../app/helper/EnvVar";
import { TemplateUserDataModel } from "../app/modules/template/models/TemplateUserDataModel";
import { addTemplateUserDataForMobileUser } from "../app/modules/template/redux/TemplateUserDataCRUD";
import { initTemplateUserData } from "../app/pages/templates/helper/InitTemplateData";
import Buttons from "../components/Buttons";
import ShareModal from "../components/ShareModal";
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "../scss/profile-preview.scss";
import "../scss/preview-page.scss";
import { firstCharCapital } from "../app/helper/helper";
import { getTemplateListForMobileUser } from "../app/modules/template/redux/TemplateListCRUD";
import { TemplateListModel } from "../app/modules/template/models/TemplateListModel";
import Template08 from "../templates/Template08";
import Template07 from "../templates/Template07";
import Template06 from "../templates/Template06";
import Template09 from "../templates/Template09";
import Template010 from "../templates/Template010";
import { FormikHelpers, FormikValues } from "formik/dist/types";
export interface MobileUserData {
    "id": string,
    "first_name": string,
    "last_name": null,
    "last_page": null,
    "email": string,
    "password": string,
    "location": null,
    "title": null,
    "description": null,
    "tags": null,
    "avatar": null,
    "language": null,
    "theme": string,
    "tfa_secret": null,
    "status": string,
    "role": string
}

type Props = {
  mobileUserData: {
    data: TemplateUserDataModel
  };
  token?: string;
}

const importView = (tempId: number) =>
  lazy(() =>
    import(`../templates/Template0${tempId}`).catch(() => {
      import(`../app/pages/NotFound/NotFoundComponent`);
    })
  );

const MobilePreview = ({mobileUserData, token } : Props) => {
  const userdata: TemplateUserDataModel = mobileUserData?.data[0];
  const navRef = React.useRef<HTMLInputElement | null>();
  const prevToggleRef = React.useRef<HTMLInputElement | null>();
  const [modalShow, setModalShow] = React.useState<boolean>(false);
  const [showApplyButton, setShowApplyButton] = React.useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [, setViews] = useState<ReactNode>();
  const [templateId, setTemplateID] = useState<number>();
  const [templateList, setTemplateList] = useState([]);
  const [tempColor, setTemplateColor] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showShareButton, setShowShareButton] = useState<boolean>(false);

  const navigate = useNavigate();

  const onToggleClick = () => {
    navRef.current.classList.toggle("show-color-picker");
  };
  const onTemplatePrvToggle = () => {
    prevToggleRef.current.classList.toggle("show-preview");
    document.body.classList.toggle("overflow-hidden");
  };
  
  const formik = useFormik({
    initialValues: userdata
      ? userdata
      : initTemplateUserData,
    onSubmit: (values: FormikValues, { setSubmitting }: FormikHelpers<FormikValues>) => {
      setLoading(true);
      setTimeout(() => {
          if(token){
          addTemplateUserDataForMobileUser({
            ...values,
            id: userdata?.id,
            status: "Published", 
            logoShape: userdata?.logoShape,
            firstName: firstCharCapital(userdata?.firstName),
            lastName: firstCharCapital(userdata?.lastName),
            selectedTemplateID: templateId
              ? templateId
              : userdata?.selectedTemplateID,
            profile_image: userdata?.profile_image?.filename_disk,
            company_logo: userdata?.company_logo?.filename_disk,
            templateColor: formik.values.templateColor ? formik.values.templateColor : userdata?.templateColor,
            templateSecondaryColor: formik.values.templateSecondaryColor ? formik.values.templateSecondaryColor : userdata?.templateSecondaryColor,
            croppedAreaLogo: userdata.croppedAreaLogo,
            croppedAreaProfile: userdata.croppedAreaProfile,
          }, token)
            .then(() => {
              setLoading(false);
              setShowApplyButton(false);
              navigate('/share-profile')
            })
            .catch(() => {
              setLoading(false);
              setSubmitting(false);
            });
          }
        }, 100);
    },
  });

  useEffect(()=>{
    if(formik?.values?.templateColor){
       loadViews(templateId ? templateId : userdata?.selectedTemplateID) 
    }
  },[formik?.values?.templateColor])

  useEffect(() => {
    setTimeout(() => {
      if (templateId) {
        setShowShareButton(true);
      }
    }, 500);  
  }, [templateId]);

  useEffect(()=>{
    getTemplateListForMobileUser().then(res => {
      setTemplateList(res?.data?.data)
    }).catch(err => console.error(err,'err'))
  },[])

  const root = document.documentElement;
  let secondaryColor: string;

  useEffect(() => {
    setTemplateColors();
  }, [templateId, templateList]);

  const setTemplateColors = () => {
    templateList?.map((template: TemplateListModel) => {
      if (template?.id === templateId) {
        setTemplateColor(template?.templateColor);
      }
      return "";
    });
  };

  async function loadViews(tempId: number) {
    setTemplateID(tempId);
    setIsLoading(true);
    const componentPromises = async (tempId: number) => {
      const View = await importView(tempId);
      if (tempId !== userdata?.selectedTemplateID || formik?.values?.templateColor !== userdata?.templateColor) {
        setShowApplyButton(true);
      } else {
        setShowApplyButton(false);
      }
      const naid: string = nanoid(10);
      return userdata && <View key={naid} editMode={false} changeTemplateColor={formik.values.templateColor} mobileUserData={userdata} skeletonLoading={isLoading} setSkeletonLoading={setIsLoading} reacTour={false} />;
    };
    tempId && componentPromises(tempId).then(setViews);
  }

  useEffect(() => {
    if (templateId) {
      if (templateId !== userdata?.selectedTemplateID) {
        setShowApplyButton(true);
      } else {
        setShowApplyButton(false);
      }
    }
      if(userdata){
        setTimeout(() => {
            if (userdata?.selectedTemplateID) {
              setTemplateID(userdata?.selectedTemplateID);
              loadViews(userdata?.selectedTemplateID);
            }else if(!userdata?.selectedTemplateID){
              loadViews(-1);
            }
        }, 1000);
      }
  }, [navigate, userdata?.selectedTemplateID]);

  const handleCircleClickColor = (val: moduleColor) => {
    const color = val?.hex ? val?.hex : "#234170";
    root?.style.setProperty("--theme05", color);
    colors?.forEach((element: COLOR) => {
      if ((element.color).toLowerCase() === val?.hex) {
        secondaryColor = element?.lightColor;
        root?.style.setProperty("--secondary05", element?.lightColor);
      }
    });
    setShowApplyButton(true);
    formik.setValues({
      ...formik.values,
      templateColor: color,
      templateSecondaryColor: secondaryColor,
    });
  };

  const handleDefaultCircleClickColor = (val: moduleColor) => {
    const color = val?.hex ? val?.hex : "#234170";
    root?.style.setProperty("--theme05", color);
    templateList?.map((template: TemplateListModel) => {
      if (template?.id === templateId) {
        setTemplateColor(template?.templateColor);
        secondaryColor = template?.templateSecondaryColor;
        root?.style.setProperty(
          "--secondary05",
          template?.templateSecondaryColor
        );
        setShowApplyButton(true);
        formik.setValues({
          ...formik.values,
          templateColor: template?.templateColor,
          templateSecondaryColor: template?.templateSecondaryColor,
        });
      }
      return "";
    });
  };

  return (
    <>
      <SkeletonTheme
        baseColor={
          formik?.values?.templateColor
            ? formik?.values?.templateColor
            : userdata?.templateColor
        }
        highlightColor={
          formik?.values?.templateSecondaryColor
            ? formik?.values?.templateSecondaryColor
            : userdata?.templateSecondaryColor
        }
      >
        <main className="main">
          <div className="profile-preview-page">
            <div className="container">
              <div className="three-col-view">
                <div className="cell-items d-md-block d-none">
                  <h2 className="heading text-center"> Select Card</h2>
                  <div className="select-cards scroll-sticky">
                    <div className="App">
                      <section className="slider">
                        <div className="slider__flex">
                          <div className="slider__images desktop">
                          {templateList &&
                              templateList.map((item: TemplateListModel, index: number) => (
                                  <div
                                    key={index}
                                    className={`slide-item 
                                `}
                                    onClick={() => loadViews(item.id)}
                                  >
                                    {item.id ===
                                    userdata?.selectedTemplateID ? (
                                      <span className="badge-active">
                                        Published
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    <div
                                          className={`slider__image ${
                                            item.id === templateId
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <img
                                            src={`${ASSEST_URL}/${item.image?.filename_disk}`}
                                            alt={`/${item.name}`}
                                            width="727"
                                            height="456"
                                          />
                                        </div>
                                     </div>
                                ))} 
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                <div className="cell-items color-choose-mobile" ref={navRef}>
                  <h2 className="heading text-center">Choose Color</h2>
                  <div className="color-section">
                    <div className="color-inner-rounded">
                      <button
                        type="button"
                        className="btn p-0 color-btn bg-transparent"
                        onClick={onToggleClick}
                      >
                        <div className="setting-icon">
                          <img
                            src="/media/images/color-picker.png"
                            alt="colorpicker"
                            width={26}
                            height={26}
                          />
                        </div>
                      </button>
                    </div>

                    <div className="color-choose-wrap">
                      <div className="d-flex mb-3 justify-content-spaceBetween">
                        <div className="d-flex flex-column w-100">
                          <img
                            src="/media/images/color-picker.png"
                            alt="colorpicker"
                            width={30}
                            height={30}
                          />
                        </div>
                        <button
                          type="button"
                          className="btn btn-nav p-0 color-closed "
                          onClick={onToggleClick}
                        >
                          <div className="icon-closed">
                            <img
                              src="/media/images/close.png"
                              alt="close"
                              width={24}
                              height={24}
                            />
                          </div>
                        </button>
                      </div>
                      <div className="d-flex justify-content-between pt-3 mt-3 mb-3 default-color-indicator flex-wrap border-top">
                        <div className="d-flex align-items-center mb-2">
                          <CirclePicker
                            className="w-auto"
                            colors={[`${tempColor}`]}
                            circleSize={20}
                            onChangeComplete={(color: moduleColor) =>
                              handleDefaultCircleClickColor(color)
                            }
                          />

                          <span className="lebel"> Default Color</span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <CirclePicker
                            className="w-auto"
                            colors={[`${userdata?.templateColor}`]}
                            circleSize={20}
                            onChangeComplete={(color: moduleColor) =>
                              handleCircleClickColor(color)
                            }
                          />
                          <span className="lebel"> Current Color</span>
                        </div>
                      </div>
                      {/* Mobile View Pick Color Start */}
                      <div>
                        <div className="select-cards scroll-sticky">
                          <div className="App">
                            <section className="slider">
                              <div className="slider__flex">
                                <div className="slider__images mobile-view">
                                  <div className="color-plates">
                                    <CirclePicker
                                      className="w-100 justify-content-between"
                                      colors={colors?.map(
                                        (col: COLOR) => col?.color
                                      )}
                                      circleSize={20}
                                      onChangeComplete={(color: moduleColor) =>
                                        handleCircleClickColor(color)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="color-plates position-relative">
                      <CirclePicker
                        className="w-100 justify-content-start"
                        colors={colors?.map((col: COLOR) => col?.color)}
                        circleSize={20}
                        onChangeComplete={(color: moduleColor) =>
                          handleCircleClickColor(color)
                        }
                      />
                      <div className="d-flex justify-content-between pt-4 mt-4 default-color-indicator flex-wrap border-top">
                        <div className="d-flex align-items-center mb-2">
                          <CirclePicker
                            className="w-auto"
                            colors={[`${tempColor}`]}
                            circleSize={20}
                            onChangeComplete={(color: moduleColor) =>
                              handleDefaultCircleClickColor(color)
                            }
                          />
                          <span> Default Color</span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <CirclePicker
                            className="w-auto"
                            colors={[`${userdata?.templateColor}`]}
                            circleSize={20}
                            onChangeComplete={(color: moduleColor) =>
                              handleCircleClickColor(color)
                            }
                          />
                          <span> Current Color</span>
                        </div>
                      </div>
                    </div>
                    {showApplyButton ? (
                      <Buttons
                        btnText={loading ? "Please wait..." : "Apply & Publish"}
                        buttonName={"button"}
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          formik.handleSubmit();
                        }}
                        className="w-100 mt-0 mt-md-3"
                        disabled={loading ? true : false}
                      ></Buttons>
                    ) : (
                      <div>
                        {showShareButton ? (
                          <div className="share-btn">
                            <Buttons
                              btnText={"Share"}
                              buttonName={"button"}
                              type="submit"
                              onClick={() => setModalShow(true)}
                              className="w-100 mt-0 mt-md-3"
                            ></Buttons>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="cell-items preview-final">
                  <h2 className="heading text-center mt-md-0 mt-4">Preview</h2>
                  {loading ? (
                    <span
                      className="d-flex text-center justify-content-center"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      Loading...
                    </span>
                  ) : (
                    <>{templateId === 6 && <Template06 
                    profilePreview={userdata?.profile_image?.filename_disk} 
                    companyLogoPreview={userdata?.company_logo?.filename_disk} 
                    editMode={true} 
                    skeletonLoading={isLoading} 
                    reacTour={false} 
                    />}
                  {templateId === 7 && <Template07 
                    profilePreview={userdata?.profile_image?.filename_disk} 
                    companyLogoPreview={userdata?.company_logo?.filename_disk} 
                    editMode={true} 
                    skeletonLoading={isLoading} 
                    reacTour={false} 
                    />}
                  {templateId === 8 && <Template08 
                    profilePreview={userdata?.profile_image?.filename_disk} 
                    companyLogoPreview={userdata?.company_logo?.filename_disk} 
                    editMode={true} 
                    skeletonLoading={isLoading} 
                    reacTour={false} 
                    />}
                  {templateId === 9 && <Template09 
                    profilePreview={userdata?.profile_image?.filename_disk} 
                    companyLogoPreview={userdata?.company_logo?.filename_disk} 
                    editMode={true} 
                    skeletonLoading={isLoading} 
                    reacTour={false} 
                    />}
                  {templateId === 10 && <Template010 
                    profilePreview={userdata?.profile_image?.filename_disk} 
                    companyLogoPreview={userdata?.company_logo?.filename_disk} 
                    editMode={true} 
                    skeletonLoading={isLoading} 
                    reacTour={false} 
                    />}
                    </>)
                    }
                </div>
              </div>

              <div className="side-temp-preview">
                <button
                  type="button"
                  className="btn p-0 color-btn bg-transparent preview-btns"
                  onClick={onTemplatePrvToggle}
                >
                  <div className="preview-icon">
                    <img
                      src="/media/images/card03.svg"
                      alt="card-icon"
                      width={30}
                      height={28}
                    />
                  </div>
                </button>
                <div className="temp-list" ref={prevToggleRef}>
                  <div className="inner-view">
                    <div className="d-flex justify-content-between mb-4 align-items-center">
                      <h5 className="mb-0 fs-6">Templates</h5>
                      <button
                        type="button"
                        className="btn btn-nav p-0 color-closed"
                        onClick={onTemplatePrvToggle}
                      >
                        <div className="icon-close">
                          <img
                            src="/media/images/close.png"
                            alt="close"
                            width={18}
                            height={18}
                          />
                        </div>
                      </button>
                    </div>
                    <div>
                      <div className="select-cards scroll-sticky">
                        <div className="App">
                          <section className="slider">
                            <div className="slider__flex">
                              <div className="slider__images mobile-view">
                              {templateList &&
                                  templateList.map(
                                    (item: TemplateListModel, index: number) => (
                                      <div
                                        key={index}
                                        className={`slide-item`}
                                        onClick={() =>
                                          loadViews(item.id)
                                        }
                                      >
                                         {item.id === userdata?.selectedTemplateID && (
                                          <span
                                            className={`${
                                              item.id ===
                                              userdata?.selectedTemplateID
                                                ? "badge-active"
                                                : ""
                                            }`}
                                          >
                                            Published
                                          </span>
                                        )}
                                        <div
                                          className={`slider__image ${
                                            item.id === templateId
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <img
                                            src={`${ASSEST_URL}/${item.image?.filename_disk}`}
                                            alt={`/${item.name}`}
                                            width="727"
                                            height="456"
                                          />
                                        </div>
                                      </div>
                                    )
                                  )} 
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ShareModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              userMobileNumber={userdata?.mobileNumber}
            />
          </div>
        </main>
      </SkeletonTheme>
    </>
  );
};

export default MobilePreview;
