import axios from "axios";
import { axiosClient } from "../../../../setup/axios/SetupAxios";
import store from "../../../../setup/redux/Store";
import {
  GET_USER_BY_TOKEN,
  LOGIN_USER,
  LOGOUT_USER,
  REFRESH_TOKEN,
  REGISTER_USER,
  USER_TEMPLATE,
  VERIFY_MOBILE_NUMBER,
  VERIFY_OTP,
} from "../../../helper/APIURL";
import { API_URL } from "../../../helper/EnvVar";


export async function verifyMobileNumber(mobileNumber: string) {
  return await axiosClient.post(`${VERIFY_MOBILE_NUMBER}`, { mobileNumber });
}

export async function verifyOtp(OTP: string, mobileNumber: string) {
  return await axiosClient.post(VERIFY_OTP, { OTP, mobileNumber });
}

export async function loginUser() {
  const mystate = store.getState();
  return await axiosClient.post(`${LOGIN_USER}`, {
    email: `${mystate.auth.mobileNumber}@pocketsite.me`,
    password: mystate.auth.mobileNumber,
  });
}

export async function refreshAccessToken() {
  const mystate = store.getState();
  return await axios.post(`${REFRESH_TOKEN}`, { refresh_token: mystate.auth.refreshToken});
}

export async function logoutUser() {
  const mystate = store.getState();
  return await axios.post(`${LOGOUT_USER}`, { refresh_token: mystate.auth.refreshToken });
}

export async function registerMobileNumber(mobileNumber: string) {
  return await axiosClient.post(`${USER_TEMPLATE}`, {
    mobileNumber,
    status: "Draft",
  });
}

export async function registerUser() {
  const mystate = store.getState();
  return await axiosClient.post(`${REGISTER_USER}`, {
    email: `${mystate.auth.mobileNumber}@pocketsite.me`,
    password: mystate.auth.mobileNumber,
    mobile_number: mystate.auth.mobileNumber,
    role: "Customers",
  });
}

export async function getUserByToken2(token : string) {
  const mystate = store.getState();

  const userByToken = await axiosClient.get(`${GET_USER_BY_TOKEN}`, {
    headers: {
      Authorization: `Bearer ${token ? token : mystate.auth.accessToken}`,
    },
  });
  
  return { userByToken }
}

export const getUserByQueryStringToken = async (accessToken: string) => {
  return await axios.get(`${API_URL}${GET_USER_BY_TOKEN}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }); 
}
