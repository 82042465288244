import { useFormik } from "formik";
import { nanoid } from "nanoid";
import React, { ReactNode, lazy, useEffect, useState } from "react";
import { CirclePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { COLOR, colors, moduleColor } from "../app/helper/constant";
import { ASSEST_URL } from "../app/helper/EnvVar";
import { RootState } from "../setup";
import { addTemplateUserData } from "../app/modules/template/redux/TemplateUserDataCRUD";
import * as templateUserDataRedux from "../app/modules/template/redux/TemplateUserDataRedux";
import * as template from "../app/modules/template/redux/TemplateListRedux";
import { initTemplateUserData } from "../app/pages/templates/helper/InitTemplateData";
import ShareModal from "../components/ShareModal";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { firstCharCapital } from "../app/helper/helper";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap-v5";
import 'react-loading-skeleton/dist/skeleton.css'
import "../scss/profile-preview.scss";
import "../scss/preview-page.scss";
import { TemplateListModel } from "../app/modules/template/models/TemplateListModel";
import { TemplateUserDataModel } from "../app/modules/template/models/TemplateUserDataModel";
import { FormikHelpers, FormikValues } from "formik/dist/types";
import Template06 from "../templates/Template06";
import Template07 from "../templates/Template07";
import Template08 from "../templates/Template08";
import Template09 from "../templates/Template09";
import Template010 from "../templates/Template010";

const importView = (tempId: number) =>
  lazy(() =>
    import(`../templates/Template0${tempId}`).catch(
      (error) => {
        console.error(`Error loading template ${tempId}:`, error);
        import(`../app/pages/NotFound/NotFoundComponent`)
      }
    )
  );

const ProfilePreview = () => {
  const templateUserData = useSelector(
    (state: RootState) => state && state.templateUserData && state.templateUserData.templateUserData && state.templateUserData.templateUserData
  );
  const { userTemplateByID } = useSelector(
    (state: RootState) => state.templateUserData
  );
  const isAuthorized = useSelector(
    (state: RootState) => state && state.auth && state.auth.isAuthorized && state.auth.isAuthorized
  );

  const navRef = React.useRef<HTMLDivElement | null>(null);
  const prevToggleRef = React.useRef<HTMLDivElement | null>(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [showApplyButton, setShowApplyButton] = React.useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [templateLoadingCheck, setTemplateLoadingCheck] = useState<boolean>(true);
  const [, setViews] = useState<ReactNode>();
  const [templateId, setTemplateID] = useState<number>();
  const [tempColor, setTemplateColor] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showShareButton, setShowShareButton] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);

  const onToggleClick = () => {
    if(navRef.current){
      navRef.current.classList.toggle("show-color-picker");
      document.body.classList.toggle("overflow-hidden");
    }
  };
  const onTemplatePrvToggle = () => {
    if(prevToggleRef.current){
      prevToggleRef.current.classList.toggle("show-preview");
      document.body.classList.toggle("overflow-hidden");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
    
  }, []);
  useEffect(() => {
    dispatch(template.action.getTemplateList());
    dispatch(templateUserDataRedux.actions.getServicesList(""));
    dispatch(templateUserDataRedux.actions.getSetTemplateUserData());
  }, []);
 
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(()=>{
    if(userTemplateByID === true){
      setIsLoading(false)
    }else{
      setIsLoading(true)
    }
  },[userTemplateByID])

  useEffect(() => {
    setTimeout(() => {
      if (templateId) {
        setShowShareButton(true);
      }
    }, 500);  
  }, [templateId]);

  const {
    template: { templateList },
  } = useSelector((state: RootState) => state);

  const root = document.documentElement;
  let secondaryColor: string;

  useEffect(() => {
    setTemplateColors();
  }, [templateId, templateList]);

  const setTemplateColors = () => {
    templateList?.map((template: TemplateListModel) => {
      if (template?.id === templateId) {
        setTemplateColor(template?.templateColor);
      }
      return "";
    });
  };

  useEffect(()=>{
    if(templateList){
      setTimeout(()=>{
        setTemplateLoadingCheck(false)
      }, 500)
    }
  },[templateList])

  async function loadViews(tempId: number) {
    setTemplateID(tempId)
    setIsLoading(true)
    const componentPromises = async (tempId: number) => {
      const View = await importView(tempId);
      if(tempId !== templateUserData?.selectedTemplateID){
        setShowApplyButton(true);
      }else{
        setShowApplyButton(false);
      }
      const naid: string = nanoid(10);
      return <View key={naid} editMode={true} skeletonLoading={isLoading} setSkeletonLoading={setIsLoading} reacTour={false}  />;
    };
    tempId && componentPromises(tempId).then(setViews);
  }

  useEffect(() => {
    if (templateId) {
      if (templateId !== templateUserData?.selectedTemplateID) {
        setShowApplyButton(true);
      } else {
        setShowApplyButton(false);
      }
    }
    if (!isAuthorized) {
      navigate("/auth/login");
    }
    if (isAuthorized) {
      if(templateUserData){
        setTimeout(() => {
          if (
            (templateUserData?.status === "Draft" ||
            templateUserData?.status === "draft") &&
            templateUserData?.id
          ) {
            navigate(`/edit-template/${templateUserData?.selectedTemplateID}`, {
              state: "abc",
            });
          } else if (
            templateUserData?.status === "Published" ||
            templateUserData?.status === "published"
          ) {
            if(templateUserData){
              if (templateUserData.selectedTemplateID) {
                setTemplateID(templateUserData?.selectedTemplateID);
                loadViews(templateUserData?.selectedTemplateID);
              } else {
                loadViews(-1);
              }
            }
          } else if (
            !templateUserData?.status &&
            !templateUserData?.id &&
            templateUserData?.selectedTemplateID
          ) {
            navigate(`/edit-template/${templateUserData?.selectedTemplateID}`, {
              state: "abc",  
            });
          } else {
            if (templateUserData?.selectedTemplateID) {
              setTemplateID(templateUserData?.selectedTemplateID);
              loadViews(templateUserData?.selectedTemplateID);
            }else if(!templateUserData?.selectedTemplateID){
              loadViews(-1);
            }
          }
        }, 1000);
      }
    } else {
      navigate("/auth/login");
    }
  }, [isAuthorized, navigate, templateUserData?.selectedTemplateID]);

  const formik = useFormik({
    initialValues: isAuthorized
      ? templateUserData
      : templateUserData
      ? templateUserData
      : initTemplateUserData,
    onSubmit: (values: TemplateUserDataModel, { setSubmitting }: FormikHelpers<FormikValues>) => {
      setLoading(true);
      setTimeout(() => {
        if (isAuthorized) {
          if(templateUserData){
           console.log(templateUserData,'templateUserData');
console.log(templateUserData?.company_logo,'templateUserData?.company_logo');
console.log(templateUserData?.company_logo?.filename_disk,'templateUserData?.company_logo?.filename_disk');
const companyLogo = templateUserData?.company_logo?.filename_disk;
const truncatedCompanyLogo = companyLogo?.substring(0, 30); // Adjust 30 to your column length
console.log(truncatedCompanyLogo,'truncatedCompanyLogo');

console.log(templateUserData?.profile_image,'templateUserData?.profile_image');
console.log(templateUserData?.profile_image?.filename_disk,'templateUserData?.profile_image?.filename_disk');
const profileImage = templateUserData?.profile_image?.filename_disk;
const truncatedProfileImage = profileImage?.substring(0, 30); // Adjust 30 to your column length
console.log(truncatedProfileImage,'truncatedProfileImage');


          addTemplateUserData({
            ...values,
            id: templateUserData.id,
            status: "Published",
            logoShape: templateUserData?.logoShape,
            firstName: firstCharCapital(templateUserData?.firstName),
            lastName: firstCharCapital(templateUserData?.lastName),
            selectedTemplateID: templateId ? templateId : templateUserData?.selectedTemplateID, 
            profile_image: truncatedProfileImage,
            company_logo: truncatedCompanyLogo,
            templateColor: templateUserData?.templateColor,
            templateSecondaryColor: templateUserData?.templateSecondaryColor,
            croppedAreaLogo: templateUserData.croppedAreaLogo,
            croppedAreaProfile: templateUserData.croppedAreaProfile,
          })
            .then(() => {
              setLoading(false);
              dispatch(
                templateUserDataRedux.actions.setTemplateUserData({
                  ...templateUserData,
                  selectedTemplateID: templateId ? templateId : templateUserData?.selectedTemplateID,
                  status: "Published",
                })
              );             
              dispatch(templateUserDataRedux.actions.unsetFromEdit());
              setShowApplyButton(false)
              if(width<=768){
                setModalShow(true)
              }
            })
            .catch((error) => {
console.log(error,'error');
console.log(values,'values');
              setLoading(false);
              setSubmitting(false);
              dispatch(templateUserDataRedux.actions.unsetFromEdit());
            });
          }
        } else {
          dispatch(templateUserDataRedux.actions.setFromEdit(true));
          setTimeout(() => {
            navigate(`/auth/login`, {
              state: {
                mobileNumber: values.mobileNumber,
              },
            });
          }, 300);
        }
      }, 100);
    },
  });

  const handleCircleClickColor = (val: moduleColor) => {
    const color = val?.hex ? val?.hex : "#234170";
    root?.style.setProperty("--theme05", color);
    colors?.forEach((element: COLOR) => {
      if ((element.color).toLowerCase() === val.hex) {
        secondaryColor = element?.lightColor;
        root?.style.setProperty("--secondary05", element?.lightColor);
      }
    });
    setShowApplyButton(true);
    if(templateUserData){
      dispatch(
        templateUserDataRedux.actions.setTemplateUserData({
          ...templateUserData,
          templateColor: color,
          templateSecondaryColor: secondaryColor && secondaryColor,
        })
      );
    }
    formik.setValues({
      ...formik.values,
      templateColor: color,
      templateSecondaryColor: secondaryColor,
    });
  };

  const handleDefaultCircleClickColor = (val: moduleColor) => {
    const color = val?.hex ? val?.hex : "#234170";
    root?.style.setProperty("--theme05", color);
    templateList?.map((template: TemplateListModel) => {
      if (template?.id === templateId) {
        setTemplateColor(template?.templateColor);
        secondaryColor = template?.templateSecondaryColor;
        root?.style.setProperty(
          "--secondary05",
          template?.templateSecondaryColor
        );
        if(templateUserData){
          dispatch(
            templateUserDataRedux.actions.setTemplateUserData({
              ...templateUserData,
              templateColor: template?.templateColor,
              templateSecondaryColor: template?.templateSecondaryColor,
            })
          );
        }
        setShowApplyButton(true);
        formik.setValues({
          ...formik.values,
          templateColor: template?.templateColor,
          templateSecondaryColor: template?.templateSecondaryColor,
        });
      }
      return "";
    });
  };

  return (
    <>
      <SkeletonTheme
        baseColor={templateUserData?.templateColor}
        highlightColor={templateUserData?.templateSecondaryColor}
      >
        <main className="main">
          <div className="profile-preview-page">
            <div className="container">
              <div className="three-col-view">
                <div className="cell-items d-md-block d-none">
                  <h2 className="heading text-center">
                    <FormattedMessage
                      id="preview.select.card"
                      defaultMessage={"Select Card"}
                    />
                  </h2>
                  <div className="select-cards scroll-sticky">
                    <div className="App">
                      <section className="slider">
                        <div className="slider__flex">
                          <div className="slider__images desktop">
                          {templateList &&
                              templateList.map((item: TemplateListModel, index: number) => (
                                  <div
                                    key={index}
                                    className={`slide-item 
                                `}
                                    onClick={() => loadViews(item.id)}
                                  >
                                    {item.id ===
                                    templateUserData?.selectedTemplateID ? (
                                      <span className="badge-active">
                                        <FormattedMessage
                                          id="published.tag"
                                          defaultMessage={"Published"}
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {templateLoadingCheck ? (
                                      <Skeleton width={243} height={393} />
                                    ) : (<div
                                          className={`slider__image ${
                                            item.id === templateId
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <img
                                            src={`${ASSEST_URL}/${item.image?.filename_disk}`}
                                            alt={`/${item.name}`}
                                            width="727"
                                            height="456"
                                          />
                                        </div>)}
                                     </div>
                                ))} 
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                <div className="cell-items color-choose-mobile" ref={navRef}>
                  <h2 className="heading text-center">
                    {" "}
                    <FormattedMessage
                      id="preview.choose.color"
                      defaultMessage={"Choose Color"}
                    />
                  </h2>
                  <div className="color-section">
                    <div
                      id="color-inner-rounded"
                      className="color-inner-rounded"
                    >
                      <button
                        type="button"
                        className="btn p-0 color-btn bg-transparent"
                        onClick={onToggleClick}
                      >
                        <div className="setting-icon">
                          <img
                            src="/media/images/color-picker.png"
                            alt="colorpicker"
                            width={26}
                            height={26}
                          />
                        </div>
                      </button>
                    </div>

                    <div className="color-choose-wrap">
                      <div className="d-flex mb-3 justify-content-spaceBetween">
                        <div className="d-flex flex-column w-100">
                          <img
                            src="/media/images/color-picker.png"
                            alt="colorpicker"
                            width={30}
                            height={30}
                          />
                        </div>
                        <button
                          type="button"
                          className="btn btn-nav p-0 color-closed w-auto"
                          onClick={onToggleClick}
                        >
                          <div className="icon-closed">
                            <img
                              src="/media/images/close.png"
                              alt="close"
                              width={24}
                              height={24}
                            />
                          </div>
                        </button>
                      </div>
                      <div className="d-flex justify-content-between pt-3 mt-3 mb-3 default-color-indicator flex-wrap border-top">
                        <div className="d-flex align-items-center mb-2">
                          <CirclePicker
                            className="w-auto"
                            colors={[`${tempColor}`]}
                            circleSize={20}
                            onChangeComplete={(color: moduleColor) =>
                              handleDefaultCircleClickColor(color)
                            }
                          />

                          <span className="lebel">
                            <FormattedMessage
                              id="preview.default.color"
                              defaultMessage={"Default Color"}
                            />
                          </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <CirclePicker
                            className="w-auto"
                            colors={[`${templateUserData?.templateColor}`]}
                            circleSize={20}
                            onChangeComplete={(color: moduleColor) =>
                              handleCircleClickColor(color)
                            }
                          />
                          <span className="lebel">
                            <FormattedMessage
                              id="preview.current.color"
                              defaultMessage={"Current Color"}
                            />
                          </span>
                        </div>
                      </div>
                      {/* Mobile View Pick Color Start */}
                      <div>
                        <div className="select-cards scroll-sticky">
                          <div className="App">
                            <section className="slider">
                              <div className="slider__flex">
                                <div className="slider__images mobile-view">
                                  <div className="color-plates">
                                    <CirclePicker
                                      className="w-100 justify-content-between"
                                      colors={colors?.map(
                                        (col: COLOR) => col?.color
                                      )}
                                      circleSize={20}
                                      onChangeComplete={(color: moduleColor) =>
                                        handleCircleClickColor(color)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                      {/* Mobile View Pick Color End */}
                    </div>

                    <div className="color-plates position-relative">
                      <CirclePicker
                        className="w-100 justify-content-start"
                        colors={colors?.map((col: COLOR) => col?.color)}
                        circleSize={20}
                        onChangeComplete={(color: moduleColor) =>
                          handleCircleClickColor(color)
                        }
                      />
                      <div className="d-flex justify-content-between pt-4 mt-4 default-color-indicator flex-wrap border-top">
                        <div className="d-flex align-items-center mb-2">
                          <CirclePicker
                            className="w-auto"
                            colors={[`${tempColor}`]}
                            circleSize={20}
                            onChangeComplete={(color: moduleColor) =>
                              handleDefaultCircleClickColor(color)
                            }
                          />
                          <span>
                            {" "}
                            <FormattedMessage
                              id="preview.default.color"
                              defaultMessage={"Default Color"}
                            />
                          </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <CirclePicker
                            className="w-auto"
                            colors={[`${templateUserData?.templateColor}`]}
                            circleSize={20}
                            onChangeComplete={(color: moduleColor) =>
                              handleCircleClickColor(color)
                            }
                          />
                          <span>
                            <FormattedMessage
                              id="preview.current.color"
                              defaultMessage={"Current Color"}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    {showApplyButton ? (
                      <Button
                        type="submit"
                        name="button"
                        className="w-100 mt-0 mt-md-3"
                        onClick={(e: { preventDefault: () => void; }) => {
                          e.preventDefault();
                          formik.handleSubmit();
                        }}
                        disabled={loading ? true : false}
                      >
                        {loading ? (
                          <FormattedMessage
                            id="otp.please.wait"
                            defaultMessage={"Please wait..."}
                          />
                        ) : (
                          <FormattedMessage
                            id="preview.apply.publish"
                            defaultMessage={"Apply & Publish"}
                          />
                        )}
                      </Button>
                    ) : (
                      <div>
                        {showShareButton ? (
                          <div className="share-btn">
                            <Button
                              type="submit"
                              name="button"
                              className="w-100 mt-0 mt-md-3"
                              onClick={() => setModalShow(true)}
                            >
                              <FormattedMessage
                                id="preview.share"
                                defaultMessage={"Share"}
                              />
                            </Button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="cell-items preview-final">
                  <h2 className="heading text-center mt-md-0 mt-4">
                    <FormattedMessage
                      id="preview.preview"
                      defaultMessage={"Preview"}
                    />
                  </h2>
                  {loading ? (
                    <span
                      className="d-flex text-center justify-content-center"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <FormattedMessage
                        id="loading"
                        defaultMessage={"Loading..."}
                      />
                    </span>
                  ) : (
                    <>{templateId === 6 && <Template06 
                    profilePreview={templateUserData?.profile_image?.filename_disk} 
                    companyLogoPreview={templateUserData?.company_logo?.filename_disk} 
                    editMode={true} 
                    skeletonLoading={isLoading} 
                    reacTour={false} 
                    />}
                  {templateId === 7 && <Template07 
                    profilePreview={templateUserData?.profile_image?.filename_disk} 
                    companyLogoPreview={templateUserData?.company_logo?.filename_disk} 
                    editMode={true} 
                    skeletonLoading={isLoading} 
                    reacTour={false} 
                    />}
                  {templateId === 8 && <Template08 
                    profilePreview={templateUserData?.profile_image?.filename_disk} 
                    companyLogoPreview={templateUserData?.company_logo?.filename_disk} 
                    editMode={true} 
                    skeletonLoading={isLoading} 
                    reacTour={false} 
                    />}
                  {templateId === 9 && <Template09 
                    profilePreview={templateUserData?.profile_image?.filename_disk} 
                    companyLogoPreview={templateUserData?.company_logo?.filename_disk} 
                    editMode={true} 
                    skeletonLoading={isLoading} 
                    reacTour={false} 
                    />}
                  {templateId === 10 && <Template010 
                    profilePreview={templateUserData?.profile_image?.filename_disk} 
                    companyLogoPreview={templateUserData?.company_logo?.filename_disk} 
                    editMode={true} 
                    skeletonLoading={isLoading} 
                    reacTour={false} 
                    />}
                    </>)
                    }
                </div>
              </div>

              <div className="side-temp-preview">
                <button
                  type="button"
                  id="side-temp-preview"
                  className="btn p-0 color-btn bg-transparent preview-btns"
                  onClick={onTemplatePrvToggle}
                >
                  <div className="preview-icon">
                    <img
                      src="/media/images/card03.svg"
                      alt="card-icon"
                      width={30}
                      height={28}
                    />
                  </div>
                </button>
                <div className="temp-list" ref={prevToggleRef}>
                  <div className="inner-view">
                    <div className="d-flex justify-content-between mb-4 align-items-center">
                      <h5 className="mb-0 fs-6">
                        <FormattedMessage
                          id="templates"
                          defaultMessage={"Templates"}
                        />
                      </h5>
                      <button
                        type="button"
                        className="btn btn-nav p-0 color-closed"
                        onClick={onTemplatePrvToggle}
                      >
                        <div className="icon-close">
                          <img
                            src="/media/images/close.png"
                            alt="close"
                            width={18}
                            height={18}
                          />
                        </div>
                      </button>
                    </div>
                    <div>
                      <div className="select-cards scroll-sticky">
                        <div className="App">
                          <section className="slider">
                            <div className="slider__flex">
                              <div className="slider__images mobile-view">
                              {templateList &&
                                  templateList.map(
                                    (item: TemplateListModel, index: number) => (
                                      <div
                                        key={index}
                                        className={`slide-item`}
                                        onClick={() =>
                                          loadViews(item.id)
                                        }
                                      >
                                         {item.id === templateUserData?.selectedTemplateID && (
                                          <span
                                            className={`${
                                              item.id ===
                                              templateUserData?.selectedTemplateID
                                                ? "badge-active"
                                                : ""
                                            }`}
                                          >
                                            Published
                                          </span>
                                        )}
                                        <div
                                          className={`slider__image ${
                                            item.id === templateId
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <img
                                            src={`${ASSEST_URL}/${item.image?.filename_disk}`}
                                            alt={`/${item.name}`}
                                            width="727"
                                            height="456"
                                          />
                                        </div>
                                      </div>
                                    )
                                  )} 
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ShareModal show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </main>
      </SkeletonTheme>
    </>
  );
};

export default ProfilePreview;
