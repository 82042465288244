import React from "react";

type Props = {
  title: string;
  templates?: string;
  editTemplate?: string;
  preview?: string;
};

function BreadcrumbSidebar({ title}: Props) {
  return (
    <>
      <div className="breadcrumbs sidebar-bread"> 
          <div className="bread-items">{title}</div> 
      </div>
    </>
  );
}

export default BreadcrumbSidebar;
