import clsx from "clsx";
import { FormikErrors } from "formik/dist/types";
import React from "react";

type Props = {
  type: string;
  id?: string;
  value?: string;
  placeholder: string;
  className: string;
  name?: string;
  rows?: number;
  autoComplete?: string;
  pattern?: string;
  required?: boolean;
  maxLength?: number;
  isTouched?: boolean;
  isDisabled?: boolean;
  errorMsg?: string | string[] | FormikErrors<string> | FormikErrors<string>[];
  autoFocus: boolean;
  inputMode?:
    | "search"
    | "text"
    | "tel"
    | "none"
    | "numeric"
    | "email"
    | "url"
    | "decimal"
    | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};
const InputField: React.FC<Props> = ({
  type,
  id,
  value,
  className,
  placeholder,
  name,
  autoComplete,
  rows,
  inputMode,
  pattern,
  required,
  maxLength,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  onKeyUp,
  onKeyPress,
  isDisabled,
  errorMsg,
  isTouched,
  autoFocus = false,
}) => {
  return (
    <>
      {type === "textarea" ? (
        <textarea
        autoComplete={autoComplete || "off"}
        className={clsx(
          `form-control ${className}`,
          {
            "is-invalid": isTouched && errorMsg,
          },
          {
            "is-valid": isTouched && !errorMsg,
          }
        )}
        name={name}
        id={id}
        rows={rows}
        inputMode={inputMode}
        value={value}
        placeholder={placeholder}
        required={required}
        maxLength={maxLength}
        disabled={isDisabled}
        autoFocus={autoFocus}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onKeyPress={onKeyPress}
        />
      ) : (
        <input
          autoComplete={autoComplete || "off"}
          type={type}
          className={clsx(
            `form-control ${className}`,
            {
              "is-invalid": isTouched && errorMsg,
            },
            {
              "is-valid": isTouched && !errorMsg,
            }
          )}
          name={name}
          id={id}
          inputMode={inputMode}
          pattern={pattern}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onKeyPress={onKeyPress}
          required={required}
          maxLength={maxLength}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={isDisabled}
          autoFocus={autoFocus}
        />
      )}
      {(isTouched && errorMsg !== "") && (
        <div className="fv-plugins-message-container danger-validate">
          <div className="fv-help-block">{errorMsg}</div>
        </div>
      )}
    </>
  );
};

export default InputField;
