import React from "react";

type Props = {
  title: string;
  templates?: string;
  editTemplate?: string;
  preview?: string;
};

function Breadcrumbs({ title, templates }: Props) {
  return (
    <>
      <div className="breadcrumbs">
        <div className="container">
          <div className="bread-items text-center">{title}</div>
          <div className="text-center">{templates && templates}</div>
        </div>
      </div>
    </>
  );
}

export default Breadcrumbs;
