import React, { useState, createContext, useEffect, ReactNode, ChangeEvent } from "react";
import { IntlProvider, MessageFormatElement } from "react-intl";
import English from "../lang/en.json";
import Hindi from "../lang/hi.json";
import { useSelector } from "react-redux";
import { RootState } from "../setup";
import { TRANSLATION_ENABLED } from "../app/helper/EnvVar";

export const Context = createContext({
  locale: "",
  selectLang: (e: ChangeEvent<HTMLSelectElement>) => e,
});

// This function will retrieve the translation message by its ID
// used for translate error message and toast error message
export const getTranslatedMessage = (id: string, defaultMessage: string, lang?: string) => {
  if (lang === "hi") {
    return Hindi[id] || defaultMessage; 
  } else if (lang === "en"){
    return English[id] || defaultMessage; 
  } else {
    return English[id] || defaultMessage;  
  }
};

type Props = {
  children: ReactNode
}

const Wrapper = (props: Props) => {
  const { lang } = useSelector((state: RootState) => state.auth);
  const local: string = lang ? lang : 'en';  
  let lang1: Record<string, string> | Record<string, MessageFormatElement[]> | undefined;
  if (local === "hi") {
    lang1 = Hindi;
  } else if (local === "en"){
    lang1 = English;
  }

  const [locale, setLocale] = useState<string>(local);
  const [message, setMessage] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>(lang1);

  useEffect(() => {
    if(lang){
      setLocale(lang)
      getTranslatedMessage('','',lang)      
      SetMessage(lang) 
    }
  },[lang])

  const SetMessage = (newLocale : string) => {
    if (newLocale === "hi") {
      setMessage(Hindi);
    }else if (newLocale === "en") {
      setMessage(English);
    }
  }

  const selectLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newLocale: string = e.target.value;
    setLocale(newLocale);
    SetMessage(newLocale);
  };

  return (
    <Context.Provider value={{ locale, selectLang }}>
      <IntlProvider locale={TRANSLATION_ENABLED === 'true' ? locale : 'en'} messages={TRANSLATION_ENABLED === 'true' ? message : English}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Wrapper;
