import React, { ReactNode, useEffect, useState } from "react";
import ToggleSwitch from "./ToggleSwitch";
import { PlansModel } from "../app/pages/Payment/planModal";

type Props = {
  checked: boolean | undefined;
  SwitchValue: string | undefined;
  views: ReactNode;
  premiumViews: ReactNode;
  plans: PlansModel[];
  handleToggleChange: (e: React.ChangeEvent<HTMLInputElement> | undefined) => void;
  handleBasicPlan: () => void;
  handlePremiumPlan: () => void;
};

function MobileViewPlans({
  checked,
  SwitchValue = "Free",
  plans,
  premiumViews,
  handleToggleChange,
  views,
  handleBasicPlan,
  handlePremiumPlan,
}: Props) {
  const [plan, setPlan] = useState<PlansModel>();

  useEffect(() => {
    if (plans) {
      plans?.filter((plan: PlansModel) => {
        if (plan?.plan_name === SwitchValue) {
          setPlan(plan);
        }
      });
    }
  }, [plans, SwitchValue]);

  return (
    <>
      <div className="text-center">
        <ToggleSwitch
          name="plan"
          checked={checked}
          value={SwitchValue}
          handleChange={(e) => handleToggleChange(e)}
        />
        <div className="row mt-2">
          <div className="col-lg-6 d-flex">
            <div className="card mb-4 mb-lg-0 w-100">
              <div className="card-body">
                <h6 className="card-title text-uppercase text-center plans-text-color">
                  {plan?.plan_name || "Free"}
                </h6> 
                {plan?.plan_name === "Free" ? views : premiumViews} 
                <div className="d-grid mt-3">
                  {plan?.plan_name &&
                    (plan?.plan_name === "Free" ? (
                      <button
                        type="button"
                        className="btn btn-primary text-uppercase"
                        onClick={() => handleBasicPlan()}
                      >
                        Continue with Basic plan
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary text-uppercase"
                        onClick={() => handlePremiumPlan()}
                      >
                        Upgrade your plan
                      </button>
                    ))}
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileViewPlans;
