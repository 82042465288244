import React from "react";
import { FormattedMessage } from "react-intl";
import Tour from "reactour";
import { getTranslatedMessage } from "../../wrapper/Wrapper";
import { useSelector } from "react-redux";
import { RootState } from "../../setup";

type ContentProps = {
  goTo: (value: number) => void;
  goToStep: (value: number) => void;
};

type TourStep = {
  selector: string;
  content: (value: ContentProps) => void;
};

type Props = {
  isTourOpen: boolean;
  setIsTourOpen: (value: boolean) => void;
  setCurrentStep: (value: number) => void;
};

function TourPremiumFeatures({
  isTourOpen,
  setIsTourOpen,
  setCurrentStep,
}: Props) {
  const { lang } = useSelector((state: RootState) => state.auth);
  const { templateUserData } = useSelector(
    (state: RootState) => state.templateUserData
  );

  const steps: TourStep[] = [
    {
      selector: ".logo-bg-shape",
      content: ({ goTo }) => (
        <>
          <span>
            <FormattedMessage
              id="premiummodal.youcan.get"
              defaultMessage={"You can get"}
            />{" "}
            <b className="plans-text-color">
              <FormattedMessage
                id="edit.template.companyLogo"
                defaultMessage={"Company Logo"}
              />
            </b>{" "}
            <FormattedMessage
              id="premiummodal.choose.premium"
              defaultMessage={"on your cards choosing premium plan"}
            />
          </span>
          <span className="d-none">
            {setTimeout(() => {
              goTo(1);
            }, 3500)}
          </span>
        </>
      ),
    },
    {
      selector: ".user-post",
      content: ({ goTo }) => (
        <>
          <span>
            <FormattedMessage
              id="premiummodal.youcan.get"
              defaultMessage={"You can get"}
            />{" "}
            <b className="plans-text-color">
              <FormattedMessage
                id="edit.template.designation"
                defaultMessage={"Designation"}
              />
            </b>{" "}
            <FormattedMessage
              id="premiummodal.choose.premium"
              defaultMessage={"on your cards choosing premium plan"}
            />
          </span>
          <span className="d-none">
            {setTimeout(() => {
              goTo(2);
            }, 3500)}
          </span>
        </>
      ),
    },
    {
      selector: ".company-name ",
      content: ({ goTo }) => (
        <>
          <span>
            <FormattedMessage
              id="premiummodal.youcan.get"
              defaultMessage={"You can get"}
            />{" "}
            <b className="plans-text-color">
              <FormattedMessage
                id="edit.template.companyname"
                defaultMessage={"Company Name"}
              />
            </b>{" "}
            <FormattedMessage
              id="premiummodal.choose.premium"
              defaultMessage={"on your cards choosing premium plan"}
            />
          </span>
          <span className="d-none">
            {setTimeout(() => {
              goTo(0);
            }, 3500)}
          </span>
        </>
      ),
    },
  ];

  const stepsWithOutCompanyLogo: TourStep[] = [
    {
      selector: ".user-post",
      content: ({ goTo }) => (
        <>
          <span>
            <FormattedMessage
              id="premiummodal.youcan.get"
              defaultMessage={"You can get"}
            />{" "}
            <b className="plans-text-color">
              <FormattedMessage
                id="edit.template.designation"
                defaultMessage={"Designation"}
              />
            </b>{" "}
            <FormattedMessage
              id="premiummodal.choose.premium"
              defaultMessage={"on your cards choosing premium plan"}
            />
          </span>
          <span className="d-none">
            {setTimeout(() => {
              goTo(1);
            }, 3500)}
          </span>
        </>
      ),
    },
    {
      selector: ".company-name ",
      content: ({ goTo }) => (
        <>
          <span>
            <FormattedMessage
              id="premiummodal.youcan.get"
              defaultMessage={"You can get"}
            />{" "}
            <b className="plans-text-color">
              <FormattedMessage
                id="edit.template.companyname"
                defaultMessage={"Company Name"}
              />
            </b>{" "}
            <FormattedMessage
              id="premiummodal.choose.premium"
              defaultMessage={"on your cards choosing premium plan"}
            />
          </span>
          <span className="d-none">
            {setTimeout(() => {
              goTo(0);
            }, 3500)}
          </span>
        </>
      ),
    },
  ];

  const stepsWithOutCompanyName: TourStep[] = [
    {
      selector: ".logo-bg-shape",
      content: ({ goTo }) => (
        <>
          <span>
            <FormattedMessage
              id="premiummodal.youcan.get"
              defaultMessage={"You can get"}
            />{" "}
            <b className="plans-text-color">
              <FormattedMessage
                id="edit.template.companyLogo"
                defaultMessage={"Company Logo"}
              />
            </b>{" "}
            <FormattedMessage
              id="premiummodal.choose.premium"
              defaultMessage={"on your cards choosing premium plan"}
            />
          </span>
          <span className="d-none">
            {setTimeout(() => {
              goTo(1);
            }, 3500)}
          </span>
        </>
      ),
    },
    {
      selector: ".user-post",
      content: ({ goTo }) => (
        <>
          <span>
            <FormattedMessage
              id="premiummodal.youcan.get"
              defaultMessage={"You can get"}
            />{" "}
            <b className="plans-text-color">
              <FormattedMessage
                id="edit.template.designation"
                defaultMessage={"Designation"}
              />
            </b>{" "}
            <FormattedMessage
              id="premiummodal.choose.premium"
              defaultMessage={"on your cards choosing premium plan"}
            />
          </span>
          <span className="d-none">
            {setTimeout(() => {
              goTo(0);
            }, 3500)}
          </span>
        </>
      ),
    },
  ];

  const stepsForDesignation: TourStep[] = [
    {
      selector: ".user-post",
      content: ({ goTo }) => (
        <>
          <span>
            <FormattedMessage
              id="premiummodal.youcan.get"
              defaultMessage={"You can get"}
            />{" "}
            <b className="plans-text-color">
              <FormattedMessage
                id="edit.template.designation"
                defaultMessage={"Designation"}
              />
            </b>{" "}
            <FormattedMessage
              id="premiummodal.choose.premium"
              defaultMessage={"on your cards choosing premium plan"}
            />
          </span>
          <span className="d-none">
            {setTimeout(() => {
              goTo(0);
            }, 3500)}
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      {templateUserData?.isCompanyLogo === false &&
      templateUserData?.isCompanyName === true ? (
        <Tour
          steps={stepsWithOutCompanyLogo}
          isOpen={isTourOpen ? isTourOpen : false}
          accentColor="#0aaec1"
          onRequestClose={() => setIsTourOpen(false)}
          rounded={8}
          badgeContent={(curr: number, tot: number) => {
            setCurrentStep(curr);
            return `${curr} of ${tot} | ${
              curr === 1
                ? getTranslatedMessage(
                    "edit.template.designation",
                    "Designation",
                    lang
                  )
                : ""
            } ${
              curr === 2
                ? getTranslatedMessage(
                    "edit.template.companyname",
                    "Company Name",
                    lang
                  )
                : ""
            }`;
          }}
        />
      ) : templateUserData?.isCompanyName === false &&
        templateUserData?.isCompanyLogo === true ? (
        <Tour
          steps={stepsWithOutCompanyName}
          isOpen={isTourOpen ? isTourOpen : false}
          accentColor="#0aaec1"
          onRequestClose={() => setIsTourOpen(false)}
          rounded={8}
          badgeContent={(curr: number, tot: number) => {
            setCurrentStep(curr);
            return `${curr} of ${tot} | ${
              curr === 1
                ? getTranslatedMessage(
                    "edit.template.companyLogo",
                    "Company logo",
                    lang
                  )
                : ""
            } ${
              curr === 2
                ? getTranslatedMessage(
                    "edit.template.designation",
                    "Designation",
                    lang
                  )
                : ""
            }`;
          }}
        />
      ) : templateUserData?.isCompanyName === false &&
        templateUserData?.isCompanyLogo === false ? (
        <Tour
          steps={stepsForDesignation}
          isOpen={isTourOpen ? isTourOpen : false}
          accentColor="#0aaec1"
          onRequestClose={() => setIsTourOpen(false)}
          rounded={8}
          badgeContent={(curr: number, tot: number) => {
            setCurrentStep(curr);
            return `${curr} of ${tot} | ${
              curr === 1
                ? getTranslatedMessage(
                    "edit.template.designation",
                    "Designation",
                    lang
                  )
                : ""
            }`;
          }}
        />
      ) : (
        <Tour
          steps={steps}
          isOpen={isTourOpen ? isTourOpen : false}
          accentColor="#0aaec1"
          onRequestClose={() => setIsTourOpen(false)}
          rounded={8}
          badgeContent={(curr: number, tot: number) => {
            setCurrentStep(curr);
            return `${curr} of ${tot} | ${
              curr === 1
                ? getTranslatedMessage(
                    "edit.template.companyLogo",
                    "Company logo",
                    lang
                  )
                : ""
            } ${
              curr === 2
                ? getTranslatedMessage(
                    "edit.template.designation",
                    "Designation",
                    lang
                  )
                : ""
            } ${
              curr === 3
                ? getTranslatedMessage(
                    "edit.template.companyname",
                    "Company Name",
                    lang
                  )
                : ""
            }`;
          }}
        />
      )}
    </>
  );
}

export default TourPremiumFeatures;
