import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

function Footer() {

  const [loading,setLoading]=useState(true)
  useEffect(()=>{
    const timer=setTimeout(() => {
      setLoading(false);
    }, 2000)
    return ()=> clearTimeout(timer);
  },[])

  return (
    <>
      {!loading && <div className="footer border-top bg-white p-lg-4 pt-3 pb-3">
        <div className="container"> 
            <div className="copy-right">
              {new Date().getFullYear()} <FormattedMessage id="footer.copyright" defaultMessage={"©Pocketsite.me. All rights reserved"} />
          </div>
        </div>
      </div>}
    </>
  );
}

export default Footer;
