import { axiosClient } from "../../../setup/axios/SetupAxios";
import { PLANS, RAZORPAY_ORDERS, RAZORPAY_SUCCESS } from "../../helper/APIURL";

type PaymentData = {
  orderDBId: string,
  orderCreationId: string,
  razorpayPaymentId: string,
  razorpayOrderId: string,
  razorpaySignature: string,
};

export async function ordersAPI(data: number | undefined) {
  return await axiosClient.post(`${RAZORPAY_ORDERS}`, { amount: data });
}

export async function paymentSuccessAPI(data: PaymentData) {
  return await axiosClient.post(`${RAZORPAY_SUCCESS}`, data);
}

export async function getPlans() {
  return await axiosClient.get(`${PLANS}`);
}
