import React from "react";

export function FallbackView() {
  return (
    <div className="splash-screen">
      <img
        src="/media/logos/main-logo.png"
        alt="pocketsite.me"
        width={50}
        height={50}
      />
      <span>Loading ...</span>
    </div>
  );
}