import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap-v5";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify"; 
import SVG from "react-inlinesvg";
import visa from "../../../templates/images/icons/visa.svg";
import mastercard from "../../../templates/images/icons/mastercard.svg";
import * as templateUserDataRedux from "../../../app/modules/template/redux/TemplateUserDataRedux";
import logo from "../../../templates/images/pocketsmall.svg";
import { RAZORPAY_KEY_ID } from "../../helper/EnvVar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { CHECKOUT_URL } from "../../helper/APIURL";
import { getPlans, ordersAPI, paymentSuccessAPI } from "./payment_api";
import { useLocation, useNavigate } from "react-router-dom";
import { addTemplateUserData } from "../../modules/template/redux/TemplateUserDataCRUD";
import "../../../index.css"
import Skeleton from "react-loading-skeleton";
import { firstCharCapital } from "../../helper/helper";
import InputField from "../../../components/edit-template/InputField";

type LocationState = {
  planName: string;
  profileImage: string;
  companyLogo: string;
};
interface Plan {
  id: number;
  plan_name: string;
  plan_price: number;
}

type HanlderResponse = {
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
}

const Razorpaycomponent: React.FC = () => {
  const templateUserData = useSelector(
    (state: RootState) => state && state.templateUserData && state.templateUserData.templateUserData && state.templateUserData.templateUserData
  );  
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const location = useLocation();
  const { state }: { state: LocationState } = location || {}; // Default to an empty object if location is null/undefined

  // Now you can safely access properties from state if it exists
  const { planName, profileImage, companyLogo } = state || {};

  const [plans, setPlans] = useState<Plan[]>([]);
  const [plan, setPlan] = useState<Plan>();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [accordian, setAccordian] = useState("0");
  const [sloading, setsLoading] = useState(true)
    
  useEffect(() => {
    const timer = setTimeout(() => {
      setsLoading(false);
    }, 2000)
    return () => clearTimeout(timer);
  }, [])
    
  useEffect(() => {
    getPlans()
      .then((res) => {
        setPlans(res?.data?.data)       
      })
      .catch((error) => console.error(error, "error"));
  },[]);

  useEffect(()=>{
    if(plans){
     plans?.filter((plan: Plan) => {
      if(planName){
        if(plan?.plan_name === planName){
          setPlan(plan)
        }
      }})
    }
    },[plans])

  function loadScript(src: string) {
    try {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    } catch (error) {
      console.log(error, "error");
    }
  }

  async function displayRazorpay() {
    try {
      if(selectedPaymentType === "subscription"){
      const res = await loadScript(CHECKOUT_URL);
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      const result = await ordersAPI(plan?.plan_price);
      if (!result) {
        alert("Server error. Are you online?");
        return;
      }
      const { amount, id, order_id } = result && result.data && result.data.data && result.data.data;
      const options = {
        key: RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: "INR",
        name: "PocketSite",
        description: "Premimum Plan",
        image: logo,
        order_id: order_id,
        handler: async function (response: HanlderResponse) {
          const data = {
            orderDBId:id,
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          await paymentSuccessAPI(data).then((res: {
            status: number;
          }) => {
            if(res?.status === 200){
              if(templateUserData){
              addTemplateUserData({
                ...templateUserData,
                id: templateUserData.id,
                status: "Published",
                firstName: firstCharCapital(templateUserData?.firstName),
                lastName: firstCharCapital(templateUserData?.lastName),
                logoShape: templateUserData?.logoShape,
                selectedTemplateID: templateUserData?.selectedTemplateID,
                profile_image: profileImage ? profileImage : templateUserData?.profile_image?.filename_disk,
                company_logo: companyLogo ? companyLogo : templateUserData?.company_logo?.filename_disk,
                templateColor: templateUserData?.templateColor,
                templateSecondaryColor: templateUserData?.templateSecondaryColor,
                plan_expired_datetime: new Date(),
                premium_plan: true,
                selected_plan: plan?.id,
              })
                .then(async () => {
                  dispatch(
                    templateUserDataRedux.actions.setTemplateUserData({
                      ...templateUserData,
                      status: "Published",
                      templateColor: templateUserData?.templateColor,
                      templateSecondaryColor:
                        templateUserData?.templateSecondaryColor,
                    })
                  );
                  dispatch(templateUserDataRedux.actions.unsetFromEdit());
                  setTimeout(() => {
                    navigate(`/profile/preview`);
                  }, 300);
                })
                .catch(() => {
                  dispatch(templateUserDataRedux.actions.unsetFromEdit());
                });
              }
            }
          }).catch((error: string) => console.log(error))
        },
        prefill: {
          name: templateUserData?.firstName ? templateUserData?.firstName + " " + templateUserData?.lastName : "User Name",
          email: templateUserData?.email ? templateUserData?.email : "user@example.com",
          phone: templateUserData?.mobileNumber ? templateUserData?.mobileNumber : "user_phone_number",
        },
        notes: {
          address: templateUserData?.address ? templateUserData?.address : "Example Corporate Office",
        },
        theme: {
          color: "#0aaec1",
          // header_background_color: 'linear-gradient(#ff9900, #ff5500)',
          // button_color: '#7159d6',
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }else{
      toast.error("please select payment method")
    }
    } catch (error) {
      console.log(error);
    }
  }

  const handleAccordionSelect = (selectedKey: string | null) => {
    setAccordian(selectedKey === "0" ? "1" : "0");
  };

  const handleFormContinue = () => {
    setAccordian(accordian === "0" ? "1" : "0");
  };

  return (
    <div className="container mt-4 mb-4 razorpay-page">
      <div className="row">
        <div className="col-lg-6">
          <Accordion
            className="mt-0"
            activeKey={accordian}
            onSelect={() => handleAccordionSelect(accordian)}
          >
            {sloading ? (
              <Skeleton
                height={468}
                width={636}
                style={{ marginTop: "30px" }}
                baseColor="#ebebeb"
                highlightColor="#d3d3d3 "
              />
            ) : (
              <Accordion.Item eventKey={accordian === "0" ? "0" : ""}>
                <Accordion.Header>
                  <div className="field-sections field-label">
                    <div className="label-20">Your Contact Details</div>
                  </div>
                </Accordion.Header>

                <Accordion.Body className="mt-4 p-0">
                  <form>
                    <div className="card-bordered">
                      <div className="row">
                        <div className="col-12">
                          <div className="floating-group ">
                            <InputField
                              type="text"
                              className="mb-3 mb-lg-4"
                              placeholder=" "
                              name="firstName"
                              id="firstName"
                              isDisabled={false}
                              autoFocus={false}
                              value={
                                name
                                  ? name
                                  : templateUserData?.firstName +
                                    " " +
                                    templateUserData?.lastName
                              }
                              onChange={(e) => setName(e.target.value)}
                            />
                            <label className="float-label">First Name *</label>
                          </div>
                          <div className="floating-group">
                            <InputField
                              type="email"
                              id="email"
                              name="email"
                              className="mb-3 mb-lg-4"
                              placeholder=" "
                              isDisabled={false}
                              autoFocus={false}
                              required
                              value={email ? email : templateUserData?.email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <label className="float-label">Email *</label>
                          </div>

                          <div className="floating-group ">
                            <InputField
                              type="tel"
                              id="phone"
                              name="phone"
                              className="mb-3 mb-lg-4"
                              required
                              placeholder=" "
                              isDisabled={false}
                              autoFocus={false}
                              value={
                                mobileNumber
                                  ? mobileNumber
                                  : templateUserData?.mobileNumber
                              }
                              onChange={(e) => setMobileNumber(e.target.value)}
                            />
                            <label className="float-label">Phone *</label>
                          </div>

                          <div className="btn-wrapper">
                            <Button
                              onClick={handleFormContinue}
                              className="w-100"
                            >
                              Continue
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {sloading ? (
              <Skeleton
                height={526}
                width={636}
                style={{ marginTop: "30px" }}
                baseColor="#ebebeb"
                highlightColor="#d3d3d3 "
              />
            ) : (
              <Accordion.Item
                eventKey={accordian === "1" ? "1" : ""}
                style={{ marginTop: "20px" }}
              >
                <Accordion.Header>
                  <div className="field-sections field-label">
                    <div className="label-20">
                      Billing Type & Payment Method
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <div className="card-bordered">
                    <div className="pt-2">
                      <input
                        type="radio"
                        id="subscriptionCheckbox"
                        name="subscription"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginTop: "15px",
                        }}
                        checked={selectedPaymentType === "subscription"}
                        onChange={() => setSelectedPaymentType("subscription")}
                      />
                      <label
                        htmlFor="subscriptionCheckbox"
                        className="mb-3"
                        style={{ marginLeft: "10px", fontSize: "20px" }}
                      >
                        Subscribe
                      </label>

                      <ul className="ps-4 m-0" style={{ color: "#757575" }}>
                        <li className="mb-3">
                          Your card will be charged automatically
                        </li>
                        <li className="mb-3"> You can unsubscribe anytime</li>
                        <li className="mb-3">
                          {" "}
                          Accepted cards{" "}
                          <SVG
                            src={visa}
                            style={{ marginRight: "2px", marginLeft: "5px" }}
                          />
                          <SVG src={mastercard} />
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex flex-column">
                      <Button onClick={displayRazorpay}>Continue To Pay</Button>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </div>

        <div className="col-lg-6">
          {sloading ? (
            <Skeleton
              height={603}
              width={636}
              baseColor="#ebebeb"
              highlightColor="#d3d3d3 "
              style={{ marginTop: "30px" }}
            />
          ) : (
            <Card
              className="overflow-hidden"
              style={{
                borderRadius: "15px",
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;",
              }}
            >
              <div className="d-flex justify-content-between align-items-center p-3 bg-theme-gradient">
                <Card.Title
                  className="text-white m-0"
                  style={{
                    fontSize: "25px",
                  }}
                >
                  {plan?.plan_name}
                </Card.Title>

                <div className="price">
                  <h3 className="text-white mb-0">
                    ₹{plan?.plan_price?.toFixed(2)}
                  </h3>
                </div>
              </div>

              <Card.Body>
                {/* <Card.Title style={{ backgroundColor: "black", height: "100px", width: "100%", color: "orange", fontSize: "50px" }}>Books</Card.Title> */}

                <h4 className="mb-3">Your Plan details</h4>
                <div className="row mb-2">
                  <div className="col-9">
                    <span>Business</span>
                  </div>
                  <div className="col-3">Idea Meet</div>
                </div>
                <div className="row mb-2 mt-4">
                  <div className="col-9">
                    <span>Plan Interval</span>
                  </div>
                  <div className="col-3">Every Year</div>
                </div>
                <div className="row mb-2 mt-4">
                  <div className="col-9">
                    <span> Plan Price</span>
                  </div>
                  <div className="col-3">
                    ₹ {plan?.plan_price?.toFixed(2)}/Year
                  </div>
                </div>
                <div className="row mb-2 mt-4">
                  <div className="col-9">
                    <span>GST (%) </span>
                  </div>
                  <div className="col-3">₹0</div>
                </div>
                <div className="row mb-2 mt-4">
                  <div className="col-9">
                    <span>Plan Amount </span>
                  </div>
                  <div className="col-3">₹ {plan?.plan_price?.toFixed(2)}</div>
                </div>
                <div
                  className="mb-12 custom-ref-style-card p-3"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.25)",
                    borderRadius: "5px",
                    padding: "5px",
                  }}
                >
                  <div className="d-flex align-items-center mb-2">
                    <input
                      type="checkbox"
                      id="myCheckbox"
                      style={{
                        marginRight: "8px",
                        width: "20px",
                        height: "20px",
                        border: "2px solid silver",
                      }}
                    />
                    <label htmlFor="myCheckbox" style={{ fontSize: "16px" }}>
                      Use reference credit
                    </label>
                  </div>
                  <p className="mb-0">Available Credits : 100</p>
                </div>

                <div className="row mb-2 pt-4">
                  <div className="col-9 ">
                    <span style={{ fontSize: "20px", color: "#000" }}>
                      Total Amount
                    </span>
                  </div>
                  <div className="col-3">
                    <strong> ₹ {plan?.plan_price?.toFixed(2)}</strong>
                  </div>
                </div>
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};
export default Razorpaycomponent;
