import React from "react";
import ReactDOM from 'react-dom/client';
// Redux
// https://github.com/rt2zz/redux-persist
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import axios from "axios";
import store, { persistor } from "./setup/redux/Store";
// import * as _redux from "./setup";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "./scss/home.scss";

import './scss/template/edit-template.scss'
import '../src/templates/scss/temp02.scss'
import '../src/templates/scss/temp03.scss'
import '../src/templates/scss/temp04.scss'
import '../src/templates/scss/temp05.scss'
import '../src/templates/scss/temp07.scss'

import Wrapper from "./wrapper/Wrapper";

// Apps
import { App } from "./app/App";
// import "./_start/assets/sass/style.scss";

import { API_URL, PUBLIC_URL } from "./app/helper/EnvVar";
import { BrowserRouter } from "react-router-dom";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
// const { PUBLIC_URL } = process.env;
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Start mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

// /* const mock = */ _redux.mockAxios(axios);
/**
 * Inject Start interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// _redux.setupAxios(axios, store);

axios.defaults.baseURL = `${API_URL}`;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render( 
  <Provider store={store}>
    <Wrapper>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        <BrowserRouter>
          <App basename={PUBLIC_URL} />
        </BrowserRouter>
      </PersistGate>
    </Wrapper>
  </Provider>);