import React from "react";
import { PUBLIC_URL } from "../../../app/helper/EnvVar";

type Props = {
  path: string | undefined;
  altText: string;
  width: string;
  height: string;
};

export const UploadImg: React.FC<Props> = ({ path = `${PUBLIC_URL}media/images/user.png`, altText = "image", width = "", height = "", }) => {
  return (
    <>
      <img
        src={(path)}
        alt={altText}
        width={width}
        height={height}
      />

    </>
  );
};
