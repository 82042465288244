import SVG from "react-inlinesvg";
import React, { useState } from "react";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import InputField from "./edit-template/InputField";
import facebook from "../templates/images/facebook.svg";
import twitter from "../templates/images/twitter.svg";
import {
  WhatsappIcon, 
} from "react-share";
import {
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../setup";

import copyIcon from "../templates/images/icons/copy.svg";
import { PUBLIC_URL } from "../app/helper/EnvVar";
import CopyToClipboard from "react-copy-to-clipboard";
import { FormattedMessage } from "react-intl";

const ShareModal = (
  props: JSX.IntrinsicAttributes &
    Omit<
      Pick<
        React.DetailedHTMLProps<
          React.HTMLAttributes<HTMLDivElement>,
          HTMLDivElement
        >,
        "key" | keyof React.HTMLAttributes<HTMLDivElement>
      > & {
        ref?:
          | ((instance: HTMLDivElement | null) => void)
          | React.RefObject<HTMLDivElement>
          | null
          | undefined;
      },
      BsPrefixProps<"div"> & ModalProps
    > &
    BsPrefixProps<"div"> &
    ModalProps & { children?: React.ReactNode} | { userMobileNumber?: string }
) => {
  const templateUserData = useSelector(
    (state: RootState) => state && state.templateUserData && state.templateUserData.templateUserData && state.templateUserData.templateUserData
  );
  const mobileNumber = props.userMobileNumber;
  const [newLocation , setNewLocation] = useState('')
  const hanldeTwitterSharing = () => {  
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    let newLocation;
    if (iOS) {
      const scheme = "twitter";
      const url = `${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`
      const openURL = window.location.href=`${url}`;
      newLocation = scheme + ":" + openURL;
      setNewLocation(scheme + ":" + openURL)
      window.location.replace(newLocation);
    } else{
      setNewLocation('Android')
    }
  }
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="share-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <FormattedMessage id="preview.share" defaultMessage={"Share"} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="social-sharing">
            <ul className="social-media mb-4 pb-4">
              <li className="item">
                <a href={`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`} target={"_blank"} className="social-link whats-app" rel="noreferrer">
                  <WhatsappShareButton
                    url={`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`}
                  >
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                </a>
                <span>
                  <FormattedMessage 
                  id="edit.share.template.whatsapp"
                  defaultMessage={"Whatsapp"}
                  />
                </span>
              </li>
             
              <li className="item" onClick={hanldeTwitterSharing}>
                <a
                  href={newLocation === 'Android' ? `https://twitter.com/intent/tweet?url=${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}` : ''}
                  target={"_blank"}
                  className="social-link twitter"
                  rel="noreferrer"
                >
                  <SVG src={twitter} />
                </a>
                <span> 
                  <FormattedMessage
                  id="edit.share.template.twitter"
                  defaultMessage={"Twitter"}
                  />
                </span>
              </li>
              <li className="item">
                <a
                  href={`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`}
                  target={"_blank"}
                  className="social-link facebook"
                  rel="noreferrer"
                >
                  <FacebookShareButton
                    url={`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`}
                  >
                    <SVG src={facebook} />
                  </FacebookShareButton>
                </a>
                <span>
                  <FormattedMessage
                  id="edit.share.template.facebook"
                  defaultMessage={"Facebook"}
                  />
                </span>
              </li>
            </ul>
          </div>
          <div className="sharelink-col">
            <b className="mb-2 d-flex">
              <FormattedMessage id="share.modal.view.URL" defaultMessage={"View Url"} />
            </b>
              <div className="position-relative" style={{ marginBottom: "20px" }}>
                <span className="mb-3 pe-10"> 
                  <a href={`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`} target="_blank" rel="noreferrer" >
                    {`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`}
                  </a>
                </span>
              </div>
          </div>
          <div className="sharelink-col">          
            <b className="mb-3 d-flex">
              <FormattedMessage id="share.modal.share.URL" defaultMessage={"Share Url"} />
            </b>
            <CopyToClipboard
              onCopy={() => {
                toast.success(<FormattedMessage id="share.modal.copy.clipboard" defaultMessage={"Copied to Clipboard!"} />);
              }}
              options={{ message: "Copied to Clipboard!" }}
              text={`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData && templateUserData?.mobileNumber}`}
            >
              <div className="position-relative">
                <InputField
                  type="copy"
                  className="mb-3 pe-5"
                  placeholder=" "
                  value={`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`}
                  autoFocus={false}
                  isDisabled={true}
                />
                <div className="copy-icon">
                  <button type="button" className="btn">
                    <SVG src={copyIcon} />
                  </button>
                </div>
              </div>
            </CopyToClipboard>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareModal;
