import Resizer from "react-image-file-resizer";

export const ResizeImage = (file: Blob) =>{
 const type = (file.type).split('/')
 return new Promise((resolve) => {
    Resizer?.imageFileResizer(
      file,           //     file, // Is the file of the image which will resized.
      250,            //     maxWidth, // Is the maxWidth of the resized new image.
      250,            //     maxHeight, // Is the maxHeight of the resized new image.
      type && type[1],         //     compressFormat, // Is the compressFormat of the resized new image.
      100,            //     quality, // Is the quality of the resized new image.
      0,              //     rotation, // Is the degree of clockwise rotation to apply to uploaded image.
      (uri) => {      //     responseUriFunc, // Is the callBack function of the resized new image URI.
        resolve(uri);
      },
      "base64",       //     outputType, // Is the output type of the resized new image.
      200,            //     minWidth, // Is the minWidth of the resized new image.
      200,            //     minHeight // Is the minHeight of the resized new image.   
    );
  });
}