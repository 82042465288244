import React, { ChangeEvent, lazy, useEffect, useState } from "react";
import { RootState } from "../setup";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import { addTemplateUserData } from "../app/modules/template/redux/TemplateUserDataCRUD";
import * as templateUserDataRedux from "../app/modules/template/redux/TemplateUserDataRedux";
import MobileViewPlans from "../components/MobileViewPlans";
import DesktopViewPlans from "../components/DesktopViewPlans";
import { getPlans } from "../app/pages/Payment/payment_api";
import { firstCharCapital } from "../app/helper/helper";
import { FormattedMessage } from "react-intl";
import { AxiosResponse } from "axios";
import { DirectusFile } from "../app/pages/templates/components/Templates/common/Crop/CropProfileImage";
import { PlansModel } from "../app/pages/Payment/planModal";

export type Responses = {
  data: Data;
  status: number;
  statusText: string;
  headers: Headers;
  config: Config;
  request: null;
}
export interface Data {
  data?: (DataEntity)[] | null;
}
export interface DataEntity {
  id: number;
  status: string;
  sort?: null;
  user_created: string;
  date_created: string;
  user_updated: string;
  date_updated: string;
  plan_name: string;
  plan_price: number;
  plan_features: string;
  plan_description: string;
}
export interface Headers {
  'cache-control': string;
  'content-type': string;
}

export interface Config {
  transitional: Transitional;
  transformRequest?: (null)[] | null;
  transformResponse?: (null)[] | null;
  timeout: number;
  xsrfCookieName: string;
  xsrfHeaderName: string;
  maxContentLength: number;
  maxBodyLength: number;
  headers: Headers1;
  baseURL: string;
  method: string;
  url: string;
}
export interface Transitional {
  silentJSONParsing: boolean;
  forcedJSONParsing: boolean;
  clarifyTimeoutError: boolean;
}
export interface Headers1 {
  Accept: string;
  Authorization: string;
}

type Props = {
  setPaymentStatus: (value: boolean) => void;
  setLoading: (value: boolean) => void;
  paymentStatus: boolean;
  templateId: number;
  uploadProfileImageFile: DirectusFile | undefined;
  uploadCompanyLogoFile: DirectusFile | undefined;
};

const importView = (tempId: number) =>
  lazy(() =>
    import(`../templates/Template0${tempId}`).catch(
      () => import(`../app/pages/NotFound/NotFoundComponent`)
    )
  );

function Plans({
  setPaymentStatus,
  setLoading,
  templateId,
  uploadCompanyLogoFile,
  uploadProfileImageFile,
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [plans, setPlans] = useState<PlansModel[] | Data>([]);
  const templateUserData = useSelector(
    (state: RootState) => state && state.templateUserData && state.templateUserData.templateUserData && state.templateUserData.templateUserData
  );
  const [views, setViews] = useState<React.ReactNode>();
  const [premiumViews, setPremiumViews] = useState<React.ReactNode>();
  const [width, setWidth] = useState(window.innerWidth);
  const [checked, setChecked] = useState<boolean | undefined>(
    templateUserData?.premium_plan
  );
  const [planName, setPlanName] = useState<string>();
  const [disabledButton, setDisabledButton] = useState<boolean | undefined>();

  useEffect(() => {
    getPlans()
      .then((res: AxiosResponse<Responses, Responses>) => setPlans(res?.data?.data))
      .catch((error: string) => console.error(error, "error"));
  },[]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if(templateUserData?.selectedTemplateID){
      loadViews(templateUserData?.selectedTemplateID);
    }
  }, []);

  useEffect(() => {
    if(templateUserData?.selectedTemplateID){
      loadViews(templateUserData?.selectedTemplateID);
    }
  }, []);

  useEffect(() => {
    if(templateUserData?.selectedTemplateID){
      loadPremiumViews(templateUserData?.selectedTemplateID);
    }
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  async function loadViews(tempId: number) {
    const componentPromises = async (tempId: number) => {
      const View = await importView(tempId);
      const naid: string = nanoid(10);
      return <View key={naid} editMode={true} premium={false} mobileUserData={templateUserData} />;
    };
    componentPromises(tempId).then(setViews);
  }

  async function loadPremiumViews(tempId: number) {
    const componentPromises = async (tempId: number) => {
      const PremiumView = await importView(tempId);
      const naid: string = nanoid(10);
      return <PremiumView key={naid} editMode={true} premium={true} mobileUserData={templateUserData}/>;
    };
    componentPromises(tempId).then(setPremiumViews);
  }

  const handleToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    setPlanName(e.target.value)
    setPaymentStatus(e.target.checked);
  };

  const handleBasicPlan = async () => {
    setDisabledButton(true)
    if(templateUserData){
      dispatch(
        templateUserDataRedux.actions.setTemplateUserData({
          ...templateUserData,
          premium_plan: false,
        })
        );
    setPaymentStatus(false);
    await addTemplateUserData({
      ...templateUserData,
      id: templateUserData.id,
      status: "Published",
      logoShape: templateUserData?.logoShape,
      premium_plan: false,
      firstName: firstCharCapital(templateUserData?.firstName),
      lastName: firstCharCapital(templateUserData?.lastName),
      selectedTemplateID: templateId
        ? templateId
        : templateUserData?.selectedTemplateID,
      profile_image: uploadProfileImageFile
        ? uploadProfileImageFile
        : templateUserData?.profile_image?.filename_disk,
      company_logo: uploadCompanyLogoFile
        ? uploadCompanyLogoFile
        : templateUserData?.company_logo?.filename_disk,
      templateColor: templateUserData?.templateColor,
      templateSecondaryColor: templateUserData?.templateSecondaryColor
        ? templateUserData?.templateSecondaryColor
        : "#f4f7fc",
      croppedAreaLogo: templateUserData.croppedAreaLogo,
      croppedAreaProfile: templateUserData.croppedAreaProfile,
    })
      .then(async () => {
        setLoading(false);
        dispatch(
          templateUserDataRedux.actions.setTemplateUserData({
            ...templateUserData,
            status: "Published",
            templateColor: templateUserData?.templateColor,
            templateSecondaryColor: templateUserData?.templateSecondaryColor,
          })
        );
        dispatch(templateUserDataRedux.actions.unsetFromEdit());
        setTimeout(() => {
          navigate(`/profile/preview`);
        }, 300);
      })
      .catch((e) => {
        console.error(e)
        setLoading(false);
        dispatch(templateUserDataRedux.actions.unsetFromEdit());
      });
    }
  };

  const handlePremiumPlan = async () => {
    setPaymentStatus(true);
    navigate("/razorpay", { state: { planName: 'Premium', profileImage: uploadProfileImageFile, companyLogo: uploadCompanyLogoFile }});
  };

  return (
    <>
      <div className="plans">
        <div className="plans-cards">
          <section className="pricing pt-4 pb-lg-5 ">
            <div className="text-center">
              <h3 style={{ color: "CaptionText" }} className="gradient-text fw-bold">
                <FormattedMessage
                  id="premiummodal.our.pricing.plans"
                  defaultMessage={"Our pricing plans"}/>     
              </h3>
            </div>
            <div className="mt-5 container">
              {width < 992 ? (
                <MobileViewPlans
                  SwitchValue={planName}
                  checked={checked}
                  handleBasicPlan={() => handleBasicPlan()}
                  handlePremiumPlan={() => handlePremiumPlan()}
                  handleToggleChange={(e) => handleToggleChange(e)}
                  views={views}
                  plans={plans}
                  premiumViews={premiumViews}
                />
                
              ) : (
                <DesktopViewPlans
                  handleBasicPlan={() => handleBasicPlan()}
                  handlePremiumPlan={() => handlePremiumPlan()}
                  handleToggleChange={(e) => handleToggleChange(e)}
                  views={views}
                  plans={plans}
                  premiumViews={premiumViews}
                  disabledButton={disabledButton}
                />
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Plans;
