import React, { useState } from "react";
import { ProtectedRoute } from "../../../components/utilities/ProtectedRoute";
import { Route, Routes, useLocation } from "react-router-dom";
import { PageDataContainer, PageTitle } from "../../../_start/layout/core";
import { profileBreadCrumbs } from "../../helper/BreadCrumbs"; 
import MobileProfilePreview from "../../../pages/MobileProfilePreview";
import NotFoundComponent from "../../pages/NotFound/NotFoundComponent";
import UserProfile from "../../../pages/UserProfile";
import ProfilePreview from "../../../pages/ProfilePreview";

const ProfilePage: React.FC = () => {
  const { search } = useLocation()
  const [formatToken, ] = useState(search && (search.startsWith('?') ? search.substring(1) : search))

  return (  
    <>
    {search ?  
        <Routes> 
          <Route path="/mobile-preview" element={<MobileProfilePreview token={formatToken} />} /> 
        </Routes> 
        : (<ProtectedRoute>
        <>
        <PageDataContainer breadcrumbs={profileBreadCrumbs} />
        <PageTitle>Profile Overview</PageTitle>
        <Routes> 
          <Route path="*" element={<NotFoundComponent />}></Route>
          <Route path="/user-profile" element={<UserProfile />} /> 
          <Route path="/preview" element={<ProfilePreview />} />
        </Routes>
      </>
    </ProtectedRoute>)}
    </>
  );
};

export default ProfilePage;
