import * as Yup from "yup";
import { getTranslatedMessage } from "../../../../../../../wrapper/Wrapper";

export const SubscribeFormValidate = Yup.object().shape({
  email: Yup.string()
      .email(getTranslatedMessage("error.message.wrongemail", "Wrong email format",'en'))
      .when("isEmail", {
        is: (value: boolean) => value,
        then: (rule) => rule.required(getTranslatedMessage("error.message.email", "Email is Required",'en')),
      })
      .required(getTranslatedMessage("error.message.email", "Email is Required",'en'))
      .nullable()
},[]);