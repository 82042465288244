import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { RootState } from "../../../../setup";
import { TemplateUserDataModel } from "../../template/models/TemplateUserDataModel";
import { AuthDataRedux } from "../redux/ReduxDispatchExport";
import { isNotEmptyObject, mobNum } from "../../../helper/helper";

import "../../../../scss/login/authentication.scss";
import SVG from "react-inlinesvg";
import InputField from "../../../../components/edit-template/InputField";
import cardAuth from "../../../../templates/images/card-auth.jpeg";
import logoCenter from "../../../../templates/images/logo-center.svg";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap-v5";
import { getTranslatedMessage } from "../../../../wrapper/Wrapper";
import { FormikValues } from "formik/dist/types";

const loginSchema = (lang: string | undefined) => Yup.object().shape({
  mobileNumber: Yup.string().max(10,
    getTranslatedMessage("error.message.mobilenumbervaild10digit", "Please enter a valid 10 digit mobile number, without special characters and spaces",lang)
    ).matches(mobNum, getTranslatedMessage("error.message.mobilenumbervaild", "Mobile number is not valid",lang))
    .required(getTranslatedMessage("error.message.mobilenumber", "Mobile number is Required",lang)),
});

const initialValues = {
  mobileNumber: "",
  // password: "demo",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthorized, OTPReceived , lang} = useSelector(
    (state: RootState) => state.auth
  );
  const templateUserData: TemplateUserDataModel| undefined = useSelector(
    (state: RootState) => state.templateUserData.templateUserData
  );
  useEffect(() => {
    if (isAuthorized) {
      navigate(`/edit-template/${templateUserData?.selectedTemplateID}`);
    }
  }, [isAuthorized]);

  useEffect(() => {
    if(OTPReceived === true){
      navigate(`/auth/otpverify`);
    }
  },[OTPReceived])
  
  useEffect(() => {
    if(isNotEmptyObject(formik.errors)){
      loginSchema(lang)
      formik.handleSubmit()
    }
  },[lang])

  const formik = useFormik({
    initialValues: templateUserData?.mobileNumber
      ? { mobileNumber: templateUserData?.mobileNumber }
      : initialValues,
    validationSchema: loginSchema(lang),
    onSubmit: (values : FormikValues) => {
      setLoading(true);
      dispatch(AuthDataRedux.actions.setMobileNumber(values.mobileNumber));
    },
  });

  return (
    <>
      <main className="main">
        <div className="authentication-page">
          <div className="container">
            <div className="auth-inner">
              <div className="row">
                <div className="col-lg-5 order-2 order-lg-1">
                  <div className="side-left  text-center">
                    <div className="top-col  ">
                      <h2 className="title">
                      <FormattedMessage
                        id="login.few.click.1"
                        defaultMessage="A few click away"
                      /> <br />
                      <FormattedMessage
                        id="login.few.click.2"
                        defaultMessage="from creating your"
                      /> <br />
                      <FormattedMessage
                        id="login.few.click.3"
                        defaultMessage="stunning digital card."
                      />
                      </h2>
                    </div>
                    <img
                      src={cardAuth}
                      alt="card-auth"
                      width={360}
                      height={360}
                    />
                  </div>
                </div>
                <div className="col order-1 order-lg-2  d-flex flex-column justify-content-center align-items-center">
                  <form
                    className="auth-form w-100"
                    onSubmit={formik.handleSubmit}
                    noValidate
                    id="kt_login_signin_form"
                  >
                    <a
                      href="/"
                      className="logo-center mb-5 d-flex justify-content-center"
                    >
                      <SVG src={logoCenter} className="logo-main" />
                    </a>
                    <div className="field-wrap">
                      <div className="mt-4 text-start d-flex flex-column">
                        <div className={`floating-group ${loading ? 'disabled' : ''}`}>
                          <InputField
                            type="tel"
                            placeholder=" "
                            {...formik.getFieldProps("mobileNumber")}
                            className={clsx(
                              `form-control form-control-lg`,
                              {
                                "is-invalid":
                                  formik.touched.mobileNumber &&
                                  formik.errors.mobileNumber,
                              },
                              {
                                "is-valid":
                                  formik.touched.mobileNumber &&
                                  !formik.errors.mobileNumber,
                              }
                            )}
                            name="mobileNumber"
                            autoComplete="off"
                            autoFocus={true}
                            isDisabled={loading ? true : false}
                            maxLength={10} 
                            pattern="\d{10}"
                          />
                          <label className="float-label">
                            <FormattedMessage 
                              id="login.mobile.no"
                              defaultMessage={"Enter Mobile No"}
                            />
                          </label>
                        </div>

                        {formik.touched.mobileNumber &&
                          formik.errors.mobileNumber && (
                            <div className="fv-plugins-message-container danger-validate">
                              <div className="fv-help-block">
                                {formik.errors.mobileNumber}
                              </div>
                            </div>
                          )}
                      </div>
                      <Button
                        type="submit"
                        name="button"
                        className="w-100 min-w-160"
                        id="kt_login_signin_form_submit_button"
                        disabled={
                          formik.isSubmitting ||
                          !formik.isValid ||
                          !formik.values.mobileNumber
                        }
                      >
                        {!loading ? <FormattedMessage id="login.continue" defaultMessage={"Continue"} /> : <FormattedMessage id="login.send.otp" defaultMessage={"Send OTP..."} />}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;