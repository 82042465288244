import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../setup";
import Footer from "../Footer";
 import Navigation from "../Navigation";
import Sidebarnav from "../Sidebarnav";
import SVG from "react-inlinesvg";  
import pocketlogo from "../../templates/images/pocket.svg";
import { Link } from "react-router-dom";
interface ProtectedRouteProps {
  children: JSX.Element;  
}

export type UseRef = {
  current:{
    classList: {
      toggle:(value: string)=> void
    }
  }
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const navRef = useRef<HTMLInputElement | null>(null);
  const [scroll, setScroll] = useState<boolean>(false);
  const isAuthorized = useSelector(
    (state: RootState) => state && state.auth && state.auth.isAuthorized && state.auth.isAuthorized
  );
  const onToggleClick = () => {
    document.body.classList.toggle("overflow-hidden");
    navRef.current.classList.toggle("show-nav-mobile");
    const colorpickerElement = document.getElementById("color-inner-rounded")
    colorpickerElement?.classList.toggle("pe-none")
    const templateButtonElement = document.getElementById("side-temp-preview")
    templateButtonElement?.classList.toggle("pe-none")
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 70);
    });
  }, []);

  return (
    <>
      {!isAuthorized ? (
        <header
          className={
            scroll ? "App-header scrolled side-nav-item" : "App-header "
          }
        >
          <Navigation />
        </header>
      ) : (
        <header id="navRef" className="side-nav-wrapper flex-row-reverse" ref={navRef}>
          <Link to={"/home"} target="_self" className="mobile-logo-new bg-white">
            <span className="logo text-center d-md-none d-block pt-2 mt-2 pe-2 me-1">
              <SVG
                src={pocketlogo}
                className="logo-main"
                width={40}
                height={40}
              />
            </span>
          </Link>

          <Sidebarnav />
          <div className="nav-bar-btn btn-nav-01">
            <button
              type="button"
              className="btn btn-nav p-0"
              onClick={onToggleClick}
            >
              <div className="menu_icon">
                <span className="one"></span>
                <span className="two"></span>
                <span className="three"></span>
              </div>
            </button>
          </div>

          <div className="nav-bar-btn btn-nav-02">
            <button
              type="button"
              className="btn btn-nav p-0"
              onClick={onToggleClick}
            >
              <div className="menu_icon">
                <span className="one"></span>
                <span className="two"></span>
                <span className="three"></span>
              </div>
            </button>
          </div>
        </header>
      )}
      {children}
      <Footer />
    </>
  );
};
